export const env_value = {
  ADDR: "서울특별시 용산구 서빙고로 17 센트럴파크",
  FOOTER_ADDR: "서울특별시 용산구 서빙고로 17 센트럴파크",
  BUSINESS_REGISTRATION_NUMBER: "848-81-01147",
  MAIL_ORDER_BUSINESS_NUMBER: "2024-서울용산-0329",
  CALL_NUMBER: "070-8899-8988",
  COMP_EMAIL: "support@ella.school",
  COMP_CEO_NAME: "임재원",
  COMP_FULL_NAME_KR: "주식회사 유나이티드어소시에이츠",
  COMP_FULL_NAME_EN: "United Associates inc.",
  REACT_APP_GMAIL_ADDRESS: "daewon@una.co.kr",
  REACT_APP_GMAIL_PASSWORD: "ghkdghs8917!",
  TERMS_OF_SERVICE_LINK: "https://88998988.notion.site/04c2f95c267e4752b4c875b4fe533d16",
  PRIVACY_POLICY_LINK: "https://88998988.notion.site/5d42d6d3807c4af4864d6dc5321a77f2",
};
  // ADDR: "서울특별시 용산구 한강대로 95 래미안용산더센트럴 225호",
  // COMP_EMAIL: "info@una.co.kr",

export const emailApiKey = {
  SERVICE_ID: "service_0aylbce",
  TEMPLATE_ID: "template_43kg0m3",
  USERR_ID: "yAm9ac7MkIZ90IqPC",
};
