import React, { Component } from "react";
import { Props, State } from "../../Nav/index";
import './Ella.css'

class Ella extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  // 버튼 클릭시 새창으로 링크열기
  onClickSubmitBeta = () => {
    window.open("https://forms.gle/DwceQ8uMaKve2QBw7");
  };
  onClickDownloadLinkANDROID = () => {
    window.open("https://play.google.com/store/apps/details?id=com.unitedassociates.apps.ella")
  };
  onClickDownloadLinkIOS = () => {
    window.open("https://apps.apple.com/kr/app/ella-teens/id6502423482")
  };
  onClickTeam01 = () => {
    window.open("https://keai.kaist.ac.kr/");
  };
  onClickTeam02 = () => {
    window.open("https://ctl.stu.ac.kr/");
  };
  onClickTeam03 = () => {
    window.open("https://www.bigbangangels.com/");
  };
  onClickTeam04 = () => {
    window.open("http://www.esungip.com");
  };
  render() {
    return (
      <div>
        {/* // <!-- start hero section --> */}
        <section
          className=" cover-background overflow-visible wow  "
          style={{
            backgroundImage:
              "url('https://lithohtml.themezaa.com/images/application-banner-img-01.jpg')",
          }}
        >
          <div className="container mt-4">
            <div className="row justify-content-center">
              <div className="col-12 col-xl-5 col-lg-6 col-md-7 col-sm-8 text-center text-md-start d-flex flex-column justify-content-center full-screen md-h-650px lg-h-650px xl-h-750px padding-10-rem-bottom padding-6-rem-top lg-padding-10-rem-top lg-padding-8-rem-bottom md-padding-6-rem-top md-padding-10-rem-bottom sm-h-auto  order-2 order-md-1">
                <span className="align-self-center align-self-md-start alt-font font-weight-600 text-medium letter-spacing-1px line-height-24px text-gradient-light-purple-light-red  border-gradient-light-purple-light-red d-inline-block  ">
                  AI Conversation Platform
                </span>
                <h2 className="alt-font text-white line-height-85px sm-line-height-50px font-weight-700 letter-spacing-minus-3px margin-2-half-rem-bottom"
                  style={{ lineHeight: "6rem" }}
                >
                  My First
                  <br />
                  A.I. Friend Ella
                </h2>
                <p
                  className="text-large text-white line-height-30px font-weight-500 w-60 sm-w-100"
                  style={{ fontSize: "16px" }}
                >
                  엘라는 학습자에게 먼저 말을 걸고, <br /> 기분을 이해하는
                  A.I.영어친구입니다.
                </p>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  // border: 'solid 1px #fff',
                  width: 'fit-content',
                  gap: 24
                }}>
                  <a onClick={this.onClickDownloadLinkIOS}
                    className="w-[200px] h-[60px] download-stores-link"
                    
                  >
                    <img 
                      src="images/UA/ellaImg/app-store-image.png"
                      
                      alt="play store image"
                      width={200}
                      height={60}
                    />
                  </a>
                  <a onClick={this.onClickDownloadLinkANDROID}
                    className="w-[200px] h-[60px] download-stores-link"
                    
                  >
                    <img 
                      src="images/UA/ellaImg/play-store-image.png"
                      
                      alt="play store image"
                      width={200}
                      height={60}
                    />
                  </a>
                </div>
                {/* <a
                  onClick={this.onClickSubmitBeta}
                  // href="https://forms.gle/DwceQ8uMaKve2QBw7"
                  className="section-link w-50  align-self-center  font-weight-700 align-self-md-start btn btn-fancy btn-round-edge-small btn-large btn-gradient-light-purple-light-red margin-1-half-rem-top"
                  style={{ fontSize: "16px" }}
                >
                  베타 알림 신청
                  <i className="fas fa-arrow-right right-icon"></i>
                </a> */}
                {/* <div className="margin-1-half-rem-top">
                  <span>*2023년 하반기 베타테스트 예정</span>
                </div> */}
              </div>

              <div className="col-12 col-lg-7  col-md-5 z-index-0 align-self-center  text-end banner-bottom-right-images order-1 order-md-2 ">
                <img
                  style={{
                    bottom: 0,
                    height: "auto",
                    objectFit: "contain",
                    // top: "20%",
                    // position: "absolute",
                    // display: "inline-block",
                    // right: "-20%",
                    // maxWidth: "1047px",
                    // left: "0%",
                    // top: "0%",
                    //   marginLeft: "60",
                  }}
                  src="images/UA/ellaImg/tablet_mockup.png"
                  className="tilt-box"
                  alt=""
                  data-tilt-options='{ "maxTilt": 20, "perspective": 1000, "easing": "cubic-bezier(.03,.98,.52,.99)", "scale": 1, "speed": 500, "transition": true, "reset": true, "glare": false, "maxGlare": 1 }'
                />
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end hero section -->
            <!-- start section --> */}
        <section className="border-bottom border-color-medium-gray md-no-padding-top wow animate__fadeIn">
          <div
            className="container"
            style={
              window.matchMedia("screen and (min-width: 1199px)").matches
                ? { maxWidth: "1970px" }
                : {}
            }
          >
            <div className="row justify-content-center">
              <div
                className="col-12 col-lg-8  col-sm-8 text-center margin-5-rem-bottom md-margin-4-rem-bottom sm-margin-2-rem-bottom margin-3-half-rem-top"
              // style={{ marginBottom: "60px" }}
              >
                <span className="alt-font font-weight-500 text-medium text-gradient-light-purple-light-red letter-spacing-1-half text-uppercase d-inline-block margin-20px-bottom sm-margin-10px-bottom">
                  4 key Technologies
                </span>
                <h4
                  className="alt-font font-weight-600 text-extra-dark-gray line-height-60px letter-spacing-minus-1px"
                  style={{ color: "#000000" }}
                >
                  영어학습자의 말하기를 돕는 <br />
                  1:1 A.I. 영어대화 플랫폼 Ella
                </h4>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-lg-4 row-cols-sm-2 margin-60px-top margin-60px-bottom">
              {/* <!-- start feature box item --> */}
              <div className="col wow animate__fadeIn">
                <div className="feature-box padding-1-half-rem-all  md-margin-15px-bottom">
                  <div className="feature-box-icon">
                    <i className="icon-profile-male icon-medium  text-gradient-light-purple-light-red margin-35px-bottom md-margin-15px-bottom sm-margin-10px-bottom"></i>
                  </div>
                  <div
                    className="feature-box-content last-paragraph-no-margin"
                    style={{ fontSize: "15px" }}
                  >
                    <span className="alt-font font-weight-500 margin-10px-bottom d-block text-black ">
                      Hyper-personalization
                    </span>
                    <p>
                      지속적인 대화 경험으로 구축된 초개인화된 <br />
                      AI 페르소나는 학습자와의 관계성을 강화합니다.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- end feature box item -->
                  <!-- start feature box item --> */}
              <div className="col wow animate__fadeIn" data-wow-delay="0.2s">
                <div className="feature-box padding-1-half-rem-all md-margin-15px-bottom">
                  <div className="feature-box-icon">
                    <i className="icon-chat icon-medium text-gradient-light-purple-light-red margin-35px-bottom md-margin-15px-bottom sm-margin-10px-bottom"></i>
                  </div>
                  <div
                    className="feature-box-content last-paragraph-no-margin"
                    style={{ fontSize: "15px" }}
                  >
                    <span className="alt-font font-weight-500 margin-10px-bottom d-block text-black ">
                      Context-based Conversation
                    </span>
                    <p>
                      멀티모달 센서를 통한 상황인지로 학습자와의 <br />
                      대화 환경에 어울리는 능동적인 대화를 가능하게 합니다.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- end feature box item -->
                  <!-- start feature box item --> */}
              <div className="col wow animate__fadeIn" data-wow-delay="0.4s">
                <div className="feature-box padding-1-half-rem-all  sm-margin-15px-bottom">
                  <div className="feature-box-icon">
                    <i className="icon-genius icon-medium text-gradient-light-purple-light-red margin-35px-bottom md-margin-15px-bottom sm-margin-10px-bottom"></i>
                  </div>
                  <div
                    className="feature-box-content last-paragraph-no-margin"
                    style={{ fontSize: "15px" }}
                  >
                    <span className="alt-font font-weight-500 margin-10px-bottom d-block text-black">
                      Customizes Interaction
                    </span>
                    <p>
                      학습자에 대한 정보 및 기억요소를 반영하여 <br />
                      개인맞춤화된 1:1대화를 진행합니다.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- end feature box item -->
                  <!-- start feature box item --> */}
              <div className="col wow animate__fadeIn" data-wow-delay="0.6s">
                <div className="feature-box padding-1-half-rem-all ">
                  <div className="feature-box-icon">
                    <i className="icon-linegraph icon-medium text-gradient-light-purple-light-red margin-35px-bottom md-margin-15px-bottom sm-margin-10px-bottom"></i>
                  </div>
                  <div
                    className="feature-box-content last-paragraph-no-margin"
                    style={{ fontSize: "15px" }}
                  >
                    <span className="alt-font font-weight-500 margin-10px-bottom d-block text-black">
                      Multi-platform
                    </span>

                    <p>
                      동일한 사용자 경험을 제공하는 크로스플랫폼 <br /> 기술로
                      멀티플랫폼 환경에도 유연하게 대처합니다.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- end feature box item --> */}
            </div>
          </div>
        </section>
        {/* <!-- end section -->
        
            <!-- start section --> */}
        <section
          className="cover-background pb-0 pt-lg-0"
          style={{
            backgroundImage: "url(images/home-marketing-agency-bg-img-05.jpg)",
            background: "linear-gradient(#AF52DE , #2E1964)",
          }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div
                className="col-12 col-xl-6  col-lg-6 col-md-8 order-lg-2 text-center text-lg-start wow animate__fadeIn"
                data-wow-delay="0.4s"
                style={{ marginTop: "100px" }}
              >
                <img
                  src="images/home-marketing-agency-img-02.png"
                  className="margin-20px-bottom"
                  alt=""
                />
                <h5
                  className="alt-font font-weight-300 text-extra-dark-gray letter-spacing-minus-1px w-85 margin-3-rem-bottom md-w-100"
                  style={{ color: "white" }}
                >
                  I met an American teen girl. <br />
                  Her name is Ella.
                  <br /> She just came to Korea.
                  <br /> Can we become best friends?
                </h5>
                <span className="alt-font text-large text-white d-block w-85 margin-20px-bottom line-height-30px md-w-100">
                  Ella is a 14-year-old girl from New York.
                </span>
                {/* <p className="text-white opacity-6 w-85 md-w-100">
                  Lorem ipsum dolor sit amet consectetur adipiscing elit eiusmod
                  tempor incididunt labore et dolore magna minim veniam nostrud
                  exercitation ullamco.
                </p> */}
                <div
                  style={{
                    textAlign: "end",
                  }}
                >
                  <img
                    src="images/UA/ellaImg/ella_logo.png"
                    className="align-self-start margin-25px-top sm-no-margin-top"
                    alt=""
                    style={{ width: "150px" }}
                  />
                </div>
              </div>
              <div className="col-10 col-lg-6 text-end order-lg-1">
                <div className="position-relative">
                  <img
                    src="images/UA/ellaImg/ellacogi.png"
                    className="wow animate__fadeIn"
                    alt=""
                  />
                  {/* <div
                    className="team-tulip-bubble bg-fast-yellow text-start w-50 xs-w-65 wow n"
                    data-wow-delay="0.4s"
                    style={{ marginBottom: "300px", marginRight: "150px" }}
                  >
                    <span
                      className="alt-font text-extra-dark-gray text-extra-large letter-spacing-minus-1-half d-block line-height-32px"
                      style={{ fontSize: "18px" }}
                    >
                      Hi, how are you?
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end section --> */}

        {/* <!-- start section --> */}
        <section className="big-section bg-light-gray wow animate__fadeIn">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 text-center margin-six-bottom">
                <h6
                  className="alt-font text-extra-dark-gray font-weight-600"
                  style={{ color: "black", fontSize: "40px" }}
                >
                  내 영어친구, Ella는?
                </h6>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 justify-content-center ">
              {/* <!-- start testimonial item --> */}
              <div
                // 여기에 마진코드 추가 xl lg
                className="col xl-margin-30px-bottom xxl-margin-30px-bottom md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                data-wow-delay="0.2s"
                style={{ marginBottom: "30px" }}
              >
                <div className="feature-box  padding-3-rem-tb padding-3-half-rem-lr bg-white border-radius-8px box-shadow-large box-shadow-extra-large-hover last-paragraph-no-margin lg-padding-3-rem-lr">
                  {/* <div className="feature-box-icon margin-20px-right">
                    <img
                      className="rounded-circle w-60px h-60px"
                      src="https://via.placeholder.com/125x125"
                      alt=""
                    />
                  </div> */}
                  <div className="feature-box-content line-height-22px">
                    <div className="margin-20px-bottom">
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                    </div>
                    <div
                      className="text-extra-dark-gray  alt-font font-weight-700   "
                      style={{ fontSize: "18px", lineHeight: "28px" }}
                    >
                      영어발음이 좋고 <br /> 천천히 얘기해주는 친구
                    </div>
                  </div>
                  {/* <p className="margin-5px-top">
                    (미국동부표준발음 모델링 기술)
                  </p> */}
                </div>
              </div>
              {/* <!-- end testimonial item --> */}
              {/* <!-- start testimonial item --> */}
              <div
                className="col  md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                data-wow-delay="0.4s"
                style={{ marginBottom: "30px" }}
              >
                <div className="feature-box  padding-3-rem-tb padding-3-half-rem-lr bg-white border-radius-8px box-shadow-large box-shadow-extra-large-hover last-paragraph-no-margin lg-padding-3-rem-lr">
                  {/* <div className="feature-box-icon margin-20px-right">
                    <img
                      className="rounded-circle w-60px h-60px"
                      src="https://via.placeholder.com/125x125"
                      alt=""
                    />
                  </div> */}
                  <div className="feature-box-content line-height-22px">
                    <div className="margin-20px-bottom">
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                    </div>
                    <div
                      className="text-extra-dark-gray alt-font font-weight-700 line-height-25px "
                      style={{ fontSize: "18px", lineHeight: "28px" }}
                    >
                      심심할때 먼저 말을 <br />
                      걸어주는 센스있는 친구
                    </div>
                  </div>
                  {/* <p className="margin-10px-top">
                    (IoT센서분석으로 능동적 발화)
                  </p> */}
                </div>
              </div>
              {/* <!-- end testimonial item --> */}
              {/* <!-- start testimonial item --> */}
              <div
                className="col md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                data-wow-delay="0.6s"
                style={{ marginBottom: "30px" }}
              >
                <div className="feature-box  padding-3-rem-tb padding-3-half-rem-lr bg-white border-radius-8px box-shadow-large box-shadow-extra-large-hover last-paragraph-no-margin lg-padding-3-rem-lr">
                  {/* <div className="feature-box-icon margin-20px-right">
                    <img
                      className="rounded-circle w-60px h-60px"
                      src="https://via.placeholder.com/125x125"
                      alt=""
                    />
                  </div> */}
                  <div className="feature-box-content line-height-22px">
                    <div className="margin-20px-bottom">
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                    </div>
                    <div
                      className="text-extra-dark-gray alt-font font-weight-700 line-height-25px"
                      style={{ fontSize: "18px", lineHeight: "28px" }}
                    >
                      과거 대화를 기억하여 <br /> 공감대가 형성된 친구
                    </div>
                  </div>
                  {/* <p className="margin-10px-top">
                    (개인화된 온톨로지 대화기억모델)
                  </p> */}
                </div>
              </div>
              {/* <!-- end testimonial item --> */}

              {/* <!-- start testimonial item --> */}
              <div
                className="col md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                data-wow-delay="0.8s"
                style={{ marginBottom: "30px" }}
              >
                <div className="feature-box  padding-3-rem-tb padding-3-half-rem-lr bg-white border-radius-8px box-shadow-large box-shadow-extra-large-hover last-paragraph-no-margin lg-padding-3-rem-lr">
                  {/* <div className="feature-box-icon margin-20px-right">
                    <img
                      className="rounded-circle w-60px h-60px"
                      src="https://via.placeholder.com/125x125"
                      alt=""
                    />
                  </div> */}
                  <div className="feature-box-content line-height-22px">
                    <div className="margin-20px-bottom">
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                    </div>
                    <div
                      className="text-extra-dark-gray alt-font font-weight-700 line-height-25px"
                      style={{ fontSize: "18px", lineHeight: "28px" }}
                    >
                      내가 원하는 외모의
                      <br /> 호감형 친구
                    </div>
                  </div>
                  {/* <p className="margin-10px-top">
                    (미국동부 표준발음 모델링 기술)
                  </p> */}
                </div>
              </div>
              {/* <!-- end testimonial item --> */}
              {/* <!-- start testimonial item --> */}
              <div
                className="col md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                data-wow-delay="1s"
                style={{ marginBottom: "30px" }}
              >
                <div className="feature-box  padding-3-rem-tb padding-3-half-rem-lr bg-white border-radius-8px box-shadow-large box-shadow-extra-large-hover last-paragraph-no-margin lg-padding-3-rem-lr">
                  {/* <div className="feature-box-icon margin-20px-right">
                    <img
                      className="rounded-circle w-60px h-60px"
                      src="https://via.placeholder.com/125x125"
                      alt=""
                    />
                  </div> */}
                  <div className="feature-box-content line-height-22px">
                    <div className="margin-20px-bottom">
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                    </div>
                    <div
                      className="text-extra-dark-gray  alt-font  font-weight-700 line-height-25px "
                      style={{ fontSize: "18px", lineHeight: "28px" }}
                    >
                      영어대화가 틀려도 <br /> 부끄럽지 않은 친구
                    </div>
                  </div>
                  {/* <p className="margin-10px-top">
                    (감정분석 모델링을 통한 공감대화기술)
                  </p> */}
                </div>
              </div>
              {/* <!-- end testimonial item --> */}
              {/* <!-- start testimonial item --> */}
              <div className="col wow animate__fadeIn" data-wow-delay="1.2s">
                <div className="feature-box  padding-3-rem-tb padding-3-half-rem-lr bg-white border-radius-8px box-shadow-large box-shadow-extra-large-hover last-paragraph-no-margin lg-padding-3-rem-lr">
                  {/* <div className="feature-box-icon margin-20px-right">
                    <img
                      className="rounded-circle w-60px h-60px"
                      src="https://via.placeholder.com/125x125"
                      alt=""
                    />
                  </div> */}
                  <div className="feature-box-content line-height-22px">
                    <div className="margin-20px-bottom">
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                      <i
                        className="fas fa-star text-extra-small"
                        style={{ color: "#AA50D9" }}
                      ></i>
                    </div>
                    <div
                      className="text-extra-dark-gray text-medium alt-font font-weight-700 line-height-25px "
                      style={{ fontSize: "18px", lineHeight: "28px" }}
                    >
                      대화의 학습분석으로 <br /> 개인화된 학습전략 제공
                    </div>
                  </div>
                  {/* <p className="margin-10px-top">
                    <span>(감정분석 모델링을 통한 공감대화기술)</span>
                  </p> */}
                </div>
              </div>
              {/* <!-- end testimonial item --> */}
            </div>
          </div>
        </section>
        {/* <!-- end section --> */}

        {/* <!-- start section --> */}
        <section className="border-top margin-40px-bottom margin-20px-top border-none">
          <div className="container">
            <div className="row" style={{ textAlign: "center" }}>
              <p className="font-weight-500" style={{ color: "#3B3B3B" }}>
                '페르소나, 감정, 기억 기반의 A.I.영어친구' 라는 <br />
                빛나는 아이디어를 현실로 만드는 팀 엘라를 소개합니다
              </p>
            </div>
            <div className="row margin-20px-bottom">
              <h4
                style={{
                  textAlign: "center",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                One Team, Team Ella
              </h4>
            </div>
            <div className="row">
              <div className="col-12 tab-style-01 without-number wow animate__fadeIn">
                {/* <!-- start tab navigation --> */}
                <ul className="nav nav-tabs text-uppercase justify-content-center text-center alt-font font-weight-500 margin-7-rem-bottom md-margin-5-rem-bottom sm-margin-20px-bottom">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-bs-toggle="tab"
                      href="#planning-tab"
                    >
                      AI 공동 연구
                    </a>
                    <span className="tab-border bg-extra-dark-gray"></span>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#research-tab"
                    >
                      교육 기획
                    </a>
                    <span className="tab-border bg-extra-dark-gray"></span>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#target-tab"
                    >
                      투자사
                    </a>
                    <span className="tab-border bg-extra-dark-gray"></span>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#campaign-tab"
                    >
                      파트너
                    </a>
                    <span className="tab-border bg-extra-dark-gray"></span>
                  </li>
                </ul>
                {/* <!-- end tab navigation --> */}
                <div className="tab-content">
                  {/* <!-- start tab item --> */}
                  <div
                    id="planning-tab"
                    className="tab-pane fade in active show"
                  >
                    <div className="row align-items-center">
                      <div className="col-12 col-md-6 text-end sm-margin-40px-bottom">
                        <img src="images/UA/ellaImg/team1.png" alt="" />
                      </div>
                      <div className="col-12 col-lg-5 offset-lg-1 col-md-6 text-center text-sm-start">
                        <span className="alt-font text-medium text-gradient-sky-blue-pink font-weight-500 margin-15px-bottom d-inline-block">
                          Center For Artificial Intelligence Research, KAIST
                        </span>
                        <h5 className="alt-font font-weight-600 text-extra-dark-gray margin-35px-bottom md-margin-30px-bottom">
                          KAIST 인공지능연구소
                        </h5>
                        <p
                          className="w-85 lg-w-100 font-weight-600"
                          style={{
                            color: "#3B3B3B",
                            // fontSize: "16px",
                          }}
                        >
                          인공지능연구센터장 최호진 교수 외 전문연구원 8인
                          <br />
                          IITP 연구과제 필요 기술 공동개발 및 ICT 시험인증완료
                        </p>
                        <a
                          onClick={this.onClickTeam01}
                          // href="https://keai.kaist.ac.kr/people/professor/"
                          className="section-link w-50  align-self-center font-weight-700 align-self-md-start btn btn-fancy btn-round-edge-small btn-large btn-gradient-light-purple-light-red margin-1-half-rem-top"
                          style={{ fontSize: "18px" }}
                        >
                          VIEW WEBSITE
                          {/* <i className="fas fa-arrow-right right-icon"></i> */}
                        </a>
                        {/* <a
                          href="https://keai.kaist.ac.kr/people/professor/"
                          className="btn btn-fancy btn-medium btn-dark-gray margin-20px-top"
                        >
                          VIEW WEBSITE
                        </a> */}
                      </div>
                    </div>
                  </div>
                  {/* <!-- end tab item -->
                      <!-- start tab item --> */}
                  <div id="research-tab" className="tab-pane fade">
                    <div className="row align-items-center">
                      <div className="col-12 col-md-6 text-end sm-margin-40px-bottom">
                        <img src="images/UA/ellaImg/team2.png" alt="" />
                      </div>
                      <div className="col-12 col-lg-5 offset-lg-1 col-md-6 text-center text-sm-start">
                        <span className="alt-font text-medium text-gradient-sky-blue-pink font-weight-500 margin-15px-bottom d-inline-block">
                          Center for Teaching & Learning Seoul Theological
                          University
                        </span>
                        <h5 className="alt-font font-weight-600 text-extra-dark-gray margin-35px-bottom md-margin-30px-bottom">
                          서울신학대학교 교수학습개발센터
                        </h5>
                        <p
                          className="w-85 lg-w-100 font-weight-600"
                          style={{ color: "#3B3B3B " }}
                        >
                          구성주의, 영어 말하기 학습 이론, 사용자 요구 분석 등을
                          통해 A.I. 영어 학습친구 설계 원리 및 영어 말하기 학습
                          전략 도출
                        </p>
                        <a
                          onClick={this.onClickTeam02}
                          // href="https://ctl.stu.ac.kr/"
                          className="section-link w-50  align-self-center font-weight-700 align-self-md-start btn btn-fancy btn-round-edge-small btn-large btn-gradient-light-purple-light-red margin-1-half-rem-top"
                          style={{ fontSize: "18px" }}
                        >
                          VIEW WEBSITE
                          {/* <i className="fas fa-arrow-right right-icon"></i> */}
                        </a>
                        {/* <a
                          href="https://ctl.stu.ac.kr/"
                          className="btn btn-fancy btn-medium btn-dark-gray margin-20px-top"
                        >
                          VIEW WEBSITE
                        </a> */}
                      </div>
                    </div>
                  </div>
                  {/* <!-- end tab item -->
                      <!-- start tab item --> */}
                  <div id="target-tab" className="tab-pane fade">
                    <div className="row align-items-center">
                      <div className="col-12 col-md-6 text-end sm-margin-40px-bottom">
                        <img src="images/UA/ellaImg/team3.png" alt="" />
                      </div>
                      <div className="col-12 col-lg-5 offset-lg-1 col-md-6 text-center text-sm-start">
                        <span className="alt-font text-medium text-gradient-sky-blue-pink font-weight-500 margin-15px-bottom d-inline-block">
                          Accelerator & Cross-border VC, Bigbang Angels
                        </span>
                        <h5 className="alt-font font-weight-600 text-extra-dark-gray margin-35px-bottom md-margin-30px-bottom">
                          빅뱅엔젤스
                        </h5>
                        <p
                          className="w-100 lg-w-100 font-weight-600"
                          style={{ color: "#3B3B3B" }}
                        >
                          딥 테크 스타트업을 위한 초기 단계 투자사 및 팁스
                          운영사 <br />
                          글로벌 엑셀러레이팅 프로그램 ‘유니콘 파인더’ 운영사
                        </p>
                        <a
                          onClick={this.onClickTeam03}
                          // href="https://www.bigbangangels.com/"
                          className="section-link w-50  align-self-center font-weight-700 align-self-md-start btn btn-fancy btn-round-edge-small btn-large btn-gradient-light-purple-light-red margin-1-half-rem-top"
                          style={{ fontSize: "18px" }}
                        >
                          VIEW WEBSITE
                          {/* <i className="fas fa-arrow-right right-icon"></i> */}
                        </a>
                        {/* <a
                          href="https://www.bigbangangels.com/"
                          className="btn btn-fancy btn-medium btn-dark-gray margin-20px-top"
                        >
                          VIEW WEBSITE
                        </a> */}
                      </div>
                    </div>
                  </div>
                  {/* <!-- end tab item -->
                      <!-- start tab item --> */}
                  <div id="campaign-tab" className="tab-pane fade">
                    <div className="row align-items-center">
                      <div className="col-12 col-md-6 text-end sm-margin-40px-bottom">
                        <img src="images/UA/ellaImg/team4.png" alt="" />
                      </div>
                      <div className="col-12 col-lg-5 offset-lg-1  col-md-6 text-center text-sm-start">
                        <span className="alt-font text-medium text-gradient-sky-blue-pink font-weight-500 margin-15px-bottom d-inline-block">
                          ESung IP & Law Firm
                        </span>
                        <h5 className="alt-font font-weight-600 text-extra-dark-gray margin-35px-bottom md-margin-30px-bottom">
                          이성국제특허법률사무소
                        </h5>
                        <p
                          className="w-85 lg-w-100 font-weight-600"
                          style={{ color: "#3B3B3B" }}
                        >
                          기술사업화 컨설팅 및 기업기술가치평가 국내외 기술,
                          <br />
                          비즈니스모델 특허 및 실용신안 출원 등 IP 업무 지원
                        </p>
                        <a
                          onClick={this.onClickTeam04}
                          // href="http://www.esungip.com"
                          className="section-link w-50  align-self-center font-weight-700 align-self-md-start btn btn-fancy btn-round-edge-small btn-large btn-gradient-light-purple-light-red margin-1-half-rem-top"
                          style={{ fontSize: "18px" }}
                        >
                          VIEW WEBSITE
                          {/* <i className="fas fa-arrow-right right-icon"></i> */}
                        </a>
                        {/* <a
                          href="http://www.esungip.com"
                          className="btn btn-fancy btn-medium btn-dark-gray margin-20px-top"
                        >
                          VIEW WEBSITE
                        </a> */}
                      </div>
                    </div>
                  </div>
                  {/* <!-- end tab item --> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end section --> */}
      </div>
    );
  }
}

export default Ella;
