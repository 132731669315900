// import react from "react";
import React, { Component } from "react";
import { State, Props } from ".";
import { Link } from "react-router-dom";
// import style from './Nav.module.scss';
// import { env_value } from '../../lib/.env';

class Nav extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      menuToggle: false,
      currentPageIndex: 0,
      menus: [
        { name: "Home", address: "/" },
        { name: "About", address: "/About" },
        { name: "Ella", address: "/Ella" },
      ],
    };
  }
  useStateMenuToggle = (flag: boolean, idx) => {
    this.setState({ menuToggle: flag, currentPageIndex: idx });
  };

  navItem = (data, index) => {
    const { name, address } = data;
    return (
      <li className="nav-item dropdown" key={index}>
        <Link
          id={index + "nav_items_id"}
          to={`${address}`}
          className="nav-link"
          onClick={(e) => {
            console.log(e.currentTarget.id);
          }}
        >
          {name}
        </Link>
      </li>
    );
  };

  render() {
    const { menus } = this.state;
    return (
      <header className="header-with-topbar">
        {/* <div className="top-bar bg-light-gray border-bottom border-color-black-transparent d-none d-md-inline-block padding-35px-lr md-no-padding-lr">
            <div className="container-fluid nav-header-container">
                <div className="d-flex flex-wrap align-items-center">
                    <div className="col-12 text-center text-sm-start col-sm-auto me-auto ps-lg-0"></div>
                    <div className="col-auto d-none d-sm-block text-end px-lg-0 font-size-0">
                        <div className="top-bar-contact">
                            <span className="top-bar-contact-list">
                                <span className={style.topbarCall}>{env_value.CALL_NUMBER}</span>
                            </span>
                            <span className="top-bar-contact-list d-none d-md-inline-block no-border-right pe-0">
                                <span className={style.topbarAddr}>{env_value.ADDR}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        <nav className="navbar navbar-expand-lg top-space navbar-light bg-white header-light fixed-top navbar-boxed header-reverse-scroll">
          <div className="container-fluid nav-header-container">
            <div className="col-6 col-lg-2 me-auto ps-lg-0">
              <a className="navbar-brand" href="/">
                <img
                  src="images/navy-symbol.png"
                  data-at2x="images/navy-symbol.png"
                  className="default-logo"
                  alt=""
                />
                <img
                  src="images/navy-symbol.png"
                  data-at2x="images/navy-symbol.png"
                  className="alt-logo"
                  alt=""
                />
                <img
                  src="images/navy-symbol.png"
                  data-at2x="images/navy-symbol.png"
                  className="mobile-logo"
                  alt=""
                />
              </a>
            </div>
            <div className="col-auto menu-order px-lg-0">
              <button
                className="navbar-toggler float-end"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-line"></span>
                <span className="navbar-toggler-line"></span>
                <span className="navbar-toggler-line"></span>
                <span className="navbar-toggler-line"></span>
              </button>
              <div
                className=" collapse navbar-collapse justify-content-center"
                id="navbarNav"
              >
                <ul className="navbar-nav alt-font">
                  {menus?.map((data, index) => this.navItem(data, index))}
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}
export default Nav;
