import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import style from './App.module.scss';
import Nav from "../Nav";

import Home from "component/Skills/Home";
import About from "component/Skills/About";
import Ella from "component/Skills/Ella";
import Footer from "component/Footer";
import ScrollToTop from "./ScrollToTop";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <ScrollToTop />
        {<Nav />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Ella" element={<Ella />} />
        </Routes>
        {<Footer />}
      </BrowserRouter>
    );
  }
}
export default App;
