// import axios from "axios";
import React, { Component, useRef, useState } from "react";
import style from './About.module.scss';
import { env_value, emailApiKey } from "../../../lib/.env";
import { Props, State } from "../../Nav/index";
// 좌우 스크롤
// import ScrollContainer from "react-scroll-container";
// // 슬라이드
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";

import emailjs from "@emailjs/browser";
// import {contactFuntions as contactSubmit} from './contact-form';

class About extends Component<Props, State> {
  targetRef: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      history: [
        {
          date: '2024. 05',
          title: '(구글) 창구프로그램 선정',
          content: []
        },
        {
          date: '2024. 04',
          title: '이노비즈(INNO-BIZ) 등록',
          content: []
        },
        {
          date: '2024. 03',
          title: '(주)야나두 업무협약서(MOU) 체결',
          content: []
        },
        {
          date: '2024. 01',
          title: '신한 퓨처스랩 “S Cube Membership (우수멤버십)” 선정',
          content: []
        },
        {
          date: '2023. 10',
          title: '(주) KT 믿:음 (LLM) 개발협력 NDA 체결 및 LETS 프로그램 등록',
          content: []
        },
        {
          date: '2023. 10',
          title: '코리아폴리스쿨 ‘AI Speaking Hub’, ‘AI Writing Hub’, ‘AI Admin Hub’ (LMS) 어플리케이션 서비스 런칭',
          content: []
        },
        {
          date: '2023. 05',
          title: '엔젤라운지 브릿지 투자 유치',
          content: []
        },
        {
          date: '2023. 04',
          title: '신한 스퀘어 브릿지 엑셀러레이팅 맴버십 선정',
          content: []
        },
        {
          date: '2023. 04',
          title: '교육정보미디어연구 (제29권 제1호) 논문 발간',
          content: [
            '명칭 : 영어 말하기 학습을 위한 AI 챗봇 활용 학습경험 설계원리 개발'
          ]
        },
        {
          date: '2023. 03',
          title: '나이스 기술신용평가 “TI-2 (매우 우수)” 등급 획득',
          content: []
        },
        {
          date: '2023. 02',
          title: '코리아폴리스쿨 업무협약서(MOU), 구매의향서(LOI) 체결',
          content: []
        },
        {
          date: '2022. 11',
          title: '(주) KT AI 파트너쉽 (Genie Labs) 체결',
          content: []
        },
        {
          date: '2022. 11',
          title: '과기부, 메타버스 얼라이언스 회원사 등록',
          content: []
        },
        {
          date: '2022. 10',
          title: 'AI 투자펀드 투자유치설명회 \'2022년 제2회 AI 파트너십데이(IR)\' 참여기업에 선정',
          content: []
        },
        {
          date: '2022. 09',
          title: '제7회 이러닝·비즈니스모델 공모전 우수상 입상',
          content: [
            '2022 에듀테크 코리아 (수상부스운영)'
          ]
        },
        {
          date: '2022. 05',
          title: '와이즈스톤 ICT 시험인증',
          content: []
        },
        {
          date: '2022. 04',
          title: '중소벤처기업부 팁스(TIPS) 기술창업 선정',
          content: [
            '(과제명: 멀티모달 상황인지와 AI딥러닝 기술 결합을 통한',
            '초개인화된 능동적 영어학습 페르소나 플랫폼)'
          ]
        },
        {
          date: '2022. 04',
          title: '기술특허 출원',
          content: [
            '(챗봇 기반 자동 생성 답변 평가를 위한 사용자 어노테이션',
            '수집 방법, 출원 번호:10-2022-0047277)'
          ]
        },
        {
          date: '2022. 01',
          title: '신용보증기금 스텝업(혁신 스타트업 성장지원 프로그램) 선정',
          content: []
        },
        {
          date: '2021. 11',
          title: '빅뱅엔젤스 투자 유치',
          content: []
        },
        {
          date: '2021. 11',
          title: 'A.I. Learning Assistant BM 특허 출원',
          content: [
            '(특허명:대화형 인공지능 아바타를 이용한 영어 말하기 교육 방법,',
            '장치 및 이에 대한 시스템, 출원번호:10-2021-0161066)'
          ]
        },
        {
          date: '2021. 07',
          title: 'KDB산업은행 2021 KDB NextONE 3기 혁신성장 스타트업 선정',
          content: []
        },
        {
          date: '2021. 06',
          title: '정보통신기획평가원(IITP) ICT R&D 혁신바우처 사업 선정 (공동연구기관 : KAIST)',
          content: [
            '(과제명: 비대면 영어학습환경을 위한 스마트홈 기반 인공지능 학습친구(AILA) 플랫폼 기술개발)'
          ]
        },
        {
          date: '2020. 07',
          title: '기업부설 연구소 설립',
          content: []
        },
        {
          date: '2020. 06',
          title: '벤처기업 인증',
          content: []
        },
        {
          date: '2018. 03',
          title: '주식회사 유나이티드어소시에이츠 설립',
          content: [
            '*설립일:2018.03.08'
          ]
        },
      ],
      patent: [
        
        {
          year: 2023,
          division: ["특허 등록(2024년)"],
          name: "초개인화 페르소나 생성 장치 및 방법",
          number: "10-2023-0064241 ",
        },
        {
          year: 2022,
          division: ["특허 등록(2024년)"],
          name: "교육 컨텐츠 주제 기반의 대화형 인공지능 아바타 영어 말하기 교육 방법, 장치 및 이에 대한 시스템",
          number: "10-2644992",
        },
        {
          year: 2021,
          division: ["특허 등록(2022년)"],
          name: "대화형 인공지능 아바타를 이용한 영어 말하기 교육 방법, 장치 및 이에 대한 시스템",
          number: "01-2418558",
        },

        {
          year: 2024,
          division: ["특허 출원"],
          name: "AI 챗봇 기반 영어 학습경험 제공 장치 및 방법",
          number: "10-2024-0008160",
        },
        {
          year: 2023,
          division: ["특허 출원"],
          name: "페르소나 검증 장치 및 방법",
          number: "10-2023-0064242",
        },
        {
          year: 2023,
          division: ["특허 출원"],
          name: "영어 말하기 교육을 위한 AI 챗봇 활용 학습경험 설계 원리 개발",
          number: "10-2023-0007109",
        },
        {
          year: 2022,
          division: ["특허 출원"],
          name: "감정 및 기억 기반의 대화형 인공지능 아바타 영어 말하기 교육 방법, 장치 및 이에 대한 시스템",
          number: "01-2022-0082017",
        },

        {
          year: 2022,
          division: ["공동 출원", "(한국과학기술원)"],
          name: "기억 기반 대화 지원 장치 및 방법",
          number: "10-2022-0080758",
        },
        {
          year: 2022,
          division: ["공동 출원", "(한국과학기술원)"],
          name: "공감 대화를 위한 프롬프트 생성 장치 및 방법",
          number: "10-2022-0077766",
        },
        {
          year: 2022,
          division: ["공동 출원", "(한국과학기술원)"],
          name: "2차원 아바타 생성 장치 및 방법",
          number: "10-2022-0077766",
        },
        {
          year: 2022,
          division: ["공동 출원", "(한국과학기술원)"],
          name: "챗봇 기반 자동 생성 답변 평가를 위한 사용자 어노테이션 수집 방법",
          number: "10-2022-0047277",
        },
      ],
      certificate: [
        {
          year: 2023,
          division: ["KCI"],
          name: "영어 말하기 학습을 위한 AI 챗봇 활용 학습경험 설계원리 개발",
          journal_name: "교육정보미디어연구",
        },
        {
          year: 2022,
          division: ["비SCI"],
          name: "전이학습 및 프레임워크 결합형 원샵 2D 아바타 생성모델",
          journal_name: "2022년 한국컴퓨터종합학술대회",
        },
        {
          year: 2022,
          division: ["비SCI"],
          name: "GPT는 공감대화를 생성할 수 있는가? 공감 대화 생성에서 제로샷 능력에 관하여",
          journal_name: "2022년 한국컴퓨터종합학술대회",
        },
        {
          year: 2022,
          division: ["비SCI"],
          name: "Toward Robust Response Selection Model for Cross Negative Sampling Condition",
          journal_name:
            "2022 IEEE International Conference on Big Data and Smart Computing (BigComp)",
        },
        {
          year: 2022,
          division: ["비SCI"],
          name: "Knowledge Management Approach for Memory Components Based on User-friendly Conversational System",
          journal_name:
            "The 5th International Workshop on Dialog Systems in conjunction with the IEEE Big Comp 2022 - 9th IEEE International Conference on Big Data and Smart Computing (IWDS 2022)",
        },
      ],
      intellectual_property: [
        {
          year: 2022,
          registration_number: ["C-2022-022569"],
          registration_name:
            "챗봇 기반 자동 생성 답변 평가를 위한 사용자 어노테이션 수집 프로그램",
          registrant: `주식회사 유나이티드어소시에이츠\n 한국과학기술원`,
        },
        {
          year: 2022,
          registration_number: ["C-2022-024206"],
          registration_name:
            "사용자 입력 발화 이해를 위한 자연어이해 모듈 프로그램",
          registrant: `주식회사 유나이티드어소시에이츠\n 한국과학기술원`,
        },
      ],
      contact: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      submit_cont: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
    };
  }

  patentTable = (value, index) => {
    // console.log(value.division.length)
    let division_length = value.division.length;
    if (division_length > 1) {
      return (
        <tr key={index}>
          <th scope="row" className="col-1 align-middle text-center">
            {value.year}
          </th>
          <td className="col-2 text-center align-middle">
            {value.division[0]}
            <br />
            {value.division[1]}
          </td>
          <th className="col-7 align-middle">{value.name}</th>
          <td className="col-2 align-middle">{value.number}</td>
        </tr>
      );
    } else {
      return (
        <tr key={index}>
          <th scope="row" className="col-1 text-center">
            {value.year}
          </th>
          <td className="col-2 text-center">{value.division[0]}</td>
          <th className="col-7">{value.name}</th>
          <td className="col-2">{value.number}</td>
        </tr>
      );
    }
  };

  certificateTable = (value, index) => {
    let division_length = value.division.length;
    if (division_length > 1) {
      return (
        <tr key={index}>
          <th scope="row" className="col-1 align-middle text-center">
            {value.year}
          </th>
          <td className="col-1 text-center align-middle">
            {value.division[0]}
            <br />
            {value.division[1]}
          </td>
          <th className="col-6 align-middle">{value.name}</th>
          <td className="col-4 align-middle">{value.journal_name}</td>
        </tr>
      );
    } else {
      return (
        <tr key={index}>
          <th scope="row" className="col-1 align-middle text-center">
            {value.year}
          </th>
          <td className="col-1 align-middle text-center">
            {value.division[0]}
          </td>
          <th className="col-6 align-middle">{value.name}</th>
          <td className="col-4 align-middle">{value.journal_name}</td>
        </tr>
      );
    }
  };

  intellectualPropertyTable = (value, index) => {
    let division_length = value.registration_number.length;
    if (division_length > 1) {
      return (
        <tr key={index}>
          <th scope="row" className="col-1 align-middle text-center">
            {value.year}
          </th>
          <td className="col-3 text-center align-middle">
            {value.registration_number[0]}
            <br />
            {value.registration_number[1]}
          </td>
          <td className="col-5 align-middle">{value.registration_name}</td>
          <td className="col-3 align-middle">{value.registrant.split('\n').map((v, i) => {
            return (
              <p className="m-0"
                key={i}> {v}</p>
            )
          })}</td>
        </tr>
      );
    } else {
      return (
        <tr key={index}>
          <th scope="row" className="col-1 align-middle text-center">
            {value.year}
          </th>
          <td className="col-3 align-middle text-center">
            {value.registration_number[0]}
          </td>
          <td className="col-5 align-middle">{value.registration_name}</td>
          <td className="col-3 align-middle">{value.registrant.split('\n').map((v, i) => {
            return (
              <p className="m-0" key={i}> {v}</p>
            )
          })}</td>
        </tr>
      );
    }
  };

  historyComponent = () => {
    const { history } = this.state;
    return (
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8 col-md-10">
          <div
            className="panel-group accordion-style-01 no-margin-bottom"
            id="accordion-style-01"
          >
            {/* <!-- start accordion item --> */}
            {history.map((v: { date: string, title: string, content: string[] }, i: number) => {
              return this.historyItem(i, v.date, v.title, v.content)
            })}
            {/* history item end */}
          </div>
        </div>
      </div>
    )
  }
  historyItem = (key: number, date: string, title: string, content: string[]) => {
    const isContent = content.length === 0 ? false : true;
    return (
      <div className="panel bg-transparent" key={key}>
        <div className="panel-heading">

          <a
            className="accordion-toggle"
            data-bs-toggle="collapse"
            data-bs-parent="#accordion-style-01"
            href={"#accordion-style-1-" + key}
          >
            <div className="panel-title">
              <span
                className={`text-extra-dark-gray font-weight-500 d-inline-block ${style.section3ContentReact1}`}

              >
                <span className="d-inline-block margin-25px-right">
                  {date}
                </span>
                {title}
                {isContent &&
                  <span style={{ "color": "#828282", marginLeft: '0.4rem' }}>{"▼"}</span>
                }
              </span>
            </div>
          </a>
        </div>
        {isContent && (
          <div
            id={"accordion-style-1-" + key}
            className="panel-collapse collapse"
            data-bs-parent="#accordion-style-01"
          >
            <div className="panel-body">
              <div className="border-left border-width-2px border-color-fast-blue padding-40px-left">
                {/* <!-- 여기에 내용 --> */}
                {content}
              </div>
            </div>
          </div>
        )}

      </div>
    )

  }

  render() {
    const {
      patent,
      certificate,
      intellectual_property,
      // contact,
      // submit_cont
    } = this.state;

    // const responsive = {
    //   desktop: {
    //     breakpoint: { max: 3000, min: 1024 },
    //     items: 3,
    //     slidesToSlide: 3, // 한 번에 이동할 슬라이드 수
    //   },
    //   tablet: {
    //     breakpoint: { max: 1024, min: 464 },
    //     items: 2,
    //     slidesToSlide: 2,
    //   },
    //   mobile: {
    //     breakpoint: { max: 464, min: 0 },
    //     items: 1,
    //     slidesToSlide: 1,
    //   }
    // }
    return (
      // <!-- start page title -->
      <>
        <section
          className="parallax bg-extra-dark-gray"
          data-parallax-background-ratio="0.5"
          style={{
            backgroundImage: "url('images/UA/aboutImg/about_main.png')",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "inherit",
            backgroundSize: "contain",
            backgroundPosition: "none",
          }}
        >
          <div className="opacity-extra-medium bg-extra-dark-gray"></div>
          <div className="container">
            <div className="row align-items-stretch justify-content-center small-screen">
              <div className="col-12 col-xl-8 col-lg-8 col-md-8 position-relative page-title-extra-small text-center d-flex justify-content-center flex-column">
                <h2 className={`text-white alt-font font-weight-500 letter-spacing-minus-1px line-height-50 sm-line-height-45 xs-line-height-30 no-margin-bottom ${style.section1TitleReact1}`}>
                  Unlock Your Potential
                  <br />
                  Discover The Possibilities
                </h2>
              </div>
              {/* <div className="down-section text-center">
                <a href="#down-section" className="section-link">
                  <i className="ti-arrow-down icon-extra-small text-white bg-transparent-black padding-15px-all xs-padding-10px-all border-radius-100"></i>
                </a>
              </div> */}
            </div>
          </div>
        </section>
        {/* <!-- end page title -->
    <!-- start section --> */}
        <section className="big-section border-top border-width-1px border-color-medium-gray pt-0 wow animate__fadeIn">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 p-0 tab-style-07">
                {/* <!-- start tab navigation --> */}
                <ul className="nav nav-tabs justify-content-center text-center text-uppercase font-weight-500 alt-font margin-10-rem-bottom lg-margin-8-rem-bottom border-bottom border-color-medium-gray md-margin-6-rem-bottom"></ul>
                {/* <!-- end tab navigation --> */}
              </div>
            </div>
          </div>
          <div
            className="container"
            style={
              window.matchMedia("screen and (min-width: 1199px)").matches
                ? { maxWidth: "1270px" }
                : {}
            }
          >
            <div className="tab-content">
              {/* <!-- start tab item --> */}
              <div
                id="turnaroundconsulting"
                className="tab-pane fade in active show"
              >
                <div className="row align-items-center">
                  <div className="col-12 col-md-6 position-relative text-end md-margin-60px-bottom">
                    <img src="images/UA/aboutImg/mision.png" alt="" />
                    <div className="bg-gradient-tussock-greenish-slate text-white text-start alt-font text-extra-large w-450px lg-w-350px padding-2-rem-tb padding-2-half-rem-lr position-absolute left-0px top-60px md-w-250px md-padding-2-rem-all">
                      {`유나이티드어소시에이츠는`}
                      <br />
                      {`A.I. Learning Assistant를 개발하는`}
                      <br />
                      {`에듀테크 스타트업입니다.`}
                    </div>
                  </div>
                  <div className="col-12 col-lg-5 col-md-6 offset-lg-1">
                    <h5 className="alt-font font-weight-500 text-extra-dark-gray margin-4-half-rem-bottom md-margin-4-rem-bottom"></h5>
                    {/* <!-- start feature box item --> */}
                    <div className="col-12 p-0 margin-3-half-rem-bottom ">
                      <div className="feature-box feature-box-left-icon">
                        <div className="feature-box-icon">
                          <i className="feather icon-feather-target icon-medium align-middle linear-gradient(to right,#556fff,#556fff,#e05fc4,#f767a6,#ff798e)"></i>
                        </div>
                        <div className="feature-box-content last-paragraph-no-margin ">
                          <div className="margin-20px-bottom">
                            <h5 className={`alt-font font-weight-500 margin-5px-bottom d-block text-extra-dark-gray ${style.section2TitleReact1}`}>
                              Mission
                            </h5>
                          </div>
                          <p
                            className={`w-80 lg-w-100 ${style.section2ContentReact1}`}
                          // style={{ fontSize: "18px", marginBottom: "100px" }}
                          >
                            아이들이 꿈을 이루는 데에 더이상 영어가 장애물이
                            되지 않도록 누구에게나 평등한 학습의 기회를
                            제공합니다.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <!-- end feature box item -->
                  <!-- start feature box item --> */}
                    <div className="col-12 p-0 margin-3-half-rem-bottom">
                      <div className="feature-box feature-box-left-icon">
                        <div className="feature-box-icon">
                          <i className="feather icon-feather-sunrise icon-medium align-middle linear-gradient(to right,#556fff,#556fff,#e05fc4,#f767a6,#ff798e)"></i>
                        </div>
                        <div className="feature-box-content last-paragraph-no-margin">
                          <div className="margin-20px-bottom">
                            <h5 className={`alt-font font-weight-500 margin-5px-bottom d-block text-extra-dark-gray ${style.section2TitleReact1}`}>
                              Vision
                            </h5>
                          </div>
                          <p
                            className={`w-80 lg-w-100 ${style.section2ContentReact1}`}
                          >
                            UA는 교육 불평등 해소를 위해 진화된 A.I. 교육을 통한
                            기회의 평준화에 진심을 담습니다.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <!-- end feature box item --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end tab item -->
        
            <!-- start section --> */}
        <section className="big-section" style={{ background: "#F7F8FC" }}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 text-center margin-five-bottom">
                <div className="alt-font text-extra-dark-gray font-weight-600">
                  <h4 className={style.section3TitleReact1}>History</h4>
                </div>
              </div>
            </div>
            {/* start history table */}
            {this.historyComponent()}
            {/* end history table */}
          </div>
        </section>
        {/* <!-- end section -->*/}
        {/* <!-- start section --> */}
        <section className="big-section wow animate__fadeIn">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 text-center margin-seven-bottom">
                <h4 className={`alt-font text-extra-dark-gray font-weight-500 ${style.section4TitleReact1}`}>
                  Patent
                </h4>
              </div>
            </div>
            {/* <ScrollContainer
              className="row justify-content-center"
              scrollHorizontal={true} // 좌우 스크롤
              vertical={false} // 세로 스크롤 비활성화
              hidescrollbars={true} // 스크롤바 숨김
            >
              <Carousel
                responsive={responsive}
                draggable={true} // 드래그로 슬라이드 이동 가능하게 함
                swipeable={true} // 터치 스와이프로 슬라이드 이동 가능하게 함
                ssr={true} // Server-side-rendering 지원
                infinite={true} // 무한 반복
                keyBoardControl={true} // 키보드로 슬라이드 이동 가능하게 함
                customTransition="all .5s" // 슬라이드 전환 애니메이션 설정
                transitionDuration={500} // 슬라이드 전환 애니메이션 지속 시간 설정 (밀리초)
                containerClass="carousel-container"
                itemClass="carousel-item-padding-40-px"
              >
                <table className={`table ${style.section4ContentReact1}`}>

                </table>
              </Carousel>
              <thead>
                <tr>
                  <th
                    className={`text-center ${style.dateHeader}`}
                    style={{ borderColor: "#CECECE" }}
                    scope="col"
                  >
                    연도
                  </th>
                  <th
                    className={`text-center ${style.dateHeader}`}
                    style={{ borderColor: "#CECECE" }}
                    scope="col"
                  >
                    구분
                  </th>
                  <th style={{ borderColor: "#CECECE" }} scope="col">
                    명칭
                  </th>
                  <th style={{ borderColor: "#CECECE" }} scope="col">
                    출원 번호
                  </th>
                </tr>
              </thead>
              <tbody>
                {patent && patent.map((v, i) => this.patentTable(v, i))}
              </tbody>
            </ScrollContainer> */}
            <div className="row justify-content-center">
              <table className={`table ${style.section4ContentReact1}`}>
                <thead>
                  <tr>
                    <th
                      className={`text-center ${style.dateHeader}`}
                      style={{ borderColor: "#CECECE" }}
                      scope="col"
                    >
                      연도
                    </th>
                    <th
                      className={`text-center ${style.dateHeader}`}
                      style={{ borderColor: "#CECECE" }}
                      scope="col"
                    >
                      구분
                    </th>
                    <th style={{ borderColor: "#CECECE" }} scope="col">
                      명칭
                    </th>
                    <th style={{ borderColor: "#CECECE" }} scope="col">
                      출원 번호
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {patent && patent.map((v, i) => this.patentTable(v, i))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
        {/* <!-- end section --> */}

        {/* <!-- start section --> */}
        <section
          className="big-section wow animate__fadeIn"
          style={{ paddingTop: 0 }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 text-center margin-seven-bottom">
                <h4 className={`alt-font text-extra-dark-gray font-weight-500 ${style.section4TitleReact1}`}>
                  Certificate
                </h4>
              </div>
            </div>
            <div className="row justify-content-center">
              <table className={`table ${style.section4ContentReact1}`}>
                <thead>
                  <tr>
                    <th
                      className={`text-center ${style.dateHeader}`}
                      style={{ borderColor: "#CECECE" }}
                      scope="col"
                    >
                      연도
                    </th>
                    <th
                      className={`text-center ${style.dateHeader}`}
                      style={{ borderColor: "#CECECE" }}
                      scope="col"
                    >
                      구분
                    </th>
                    <th style={{ borderColor: "#CECECE" }} scope="col">
                      논문명
                    </th>
                    <th style={{ borderColor: "#CECECE" }} scope="col">
                      학술지명
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {certificate &&
                    certificate.map((v, i) => this.certificateTable(v, i))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
        {/* <!-- end section --> */}

        {/* <!-- start section --> */}
        <section
          className="big-section margin-80px-bottom wow  animate__fadeIn"
          style={{ paddingTop: 0 }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 text-center margin-seven-bottom">
                <h4 className={`alt-font text-extra-dark-gray font-weight-500 ${style.section4TitleReact1}`}>
                  Intellectual Property
                </h4>
              </div>
            </div>
            <div className="row justify-content-center">
              <table className={`table ${style.section4ContentReact1}`}>
                <thead>
                  <tr>
                    <th
                      className={`text-center ${style.dateHeader}`}
                      style={{ borderColor: "#CECECE" }}
                      scope="col"
                    >
                      연도
                    </th>
                    <th
                      className={`text-center ${style.dateHeader}`}
                      style={{ borderColor: "#CECECE" }}
                      scope="col"
                    >
                      구분
                    </th>
                    <th style={{ borderColor: "#CECECE" }} scope="col">
                      등록명칭
                    </th>
                    <th style={{ borderColor: "#CECECE" }} scope="col">
                      등록인
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {intellectual_property &&
                    intellectual_property.map((v, i) =>
                      this.intellectualPropertyTable(v, i)
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </section>
        {/* <!-- end section --> */}

        {/* <!-- 표3개 넣기 -->=
intellectual_property
            <!-- 표3개 끝 -->
            <!-- 동영상 시작 -->
            <!-- start section --> */}
        <section
          className="big-section cover-background"
          style={{ backgroundImage: "url('images/UA/aboutImg/banner.png')" }}
        >
          {/* <div className="opacity-extra-medium-2 bg-dark-slate-blue"></div> */}
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-xl-7 col-lg-8 col-md-10 text-center position-relative">
                {/* <a
                  href="https://www.youtube.com/watch?v=g0f_BRYJLJE"
                  className="popup-youtube video-icon-box video-icon-large position-relative d-inline-block margin-3-half-rem-bottom wow animate__bounceIn"
                >
                  <span>
                    <span className="video-icon bg-neon-blue">
                      <i className="icon-simple-line-control-play text-white"></i>
                      <span className="video-icon-sonar">
                        <span className="video-icon-sonar-afr bg-neon-blue"></span>
                      </span>
                    </span>
                  </span>
                </a> */}
                <span className="text-white alt-font text-uppercase letter-spacing-1px wow animate__bounceIn">
                  {`인류교육의 진화와 기회의 평준화에 진심을 담는`}
                </span>
                <h3 className="alt-font text-white font-weight-600 margin-45px-bottom md-margin-35px-bottom xs-margin-25px-bottom wow animate__fadeIn">
                  <img src="images/UA/aboutImg/textlogo_w.png" />
                </h3>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end section -->
            <!-- 동영상 끝 -->

          <!-- start section --> */}
        <section
          className="big-section cover-background wow animate__fadeIn"
          style={{ background: "#F7F8FC" }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div
                className="col-md-12 text-center margin-seven-bottom wow animate__fadeIn"
                data-wow-delay="0.2s"
              >
                <div className="alt-font text-extra-dark-gray font-weight-600">
                  <span className={style.section5TitleReact1}>United Associates BI</span>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-lg-4 position-relative md-margin-9-rem-bottom wow ">
                <img src="images/UA/aboutImg/bi.png" alt="" />
              </div>
              <div className="col-10 col-lg-7 offset-lg-1">
                <h6 className={`alt-font text-extra-dark-gray letter-spacing-minus-1px font-weight-600 margin-4-rem-bottom md-margin-3-rem-bottom wow ${style.section5TitleReact2}`}>
                  Adaptive Intelligence Educational Platform
                </h6>
                <div className="row">
                  {/* <!-- start feature box item --> */}
                  <div
                    className="col-12 wow animate__fadeIn"
                    data-wow-delay="0.2s"
                  >
                    <div className="feature-box feature-box-left-icon">
                      <div className="feature-box-content last-paragraph-no-margin">
                        <p
                          className={`w-100 xs-w-100 ${style.section5ContentReact1}`}

                        >
                          A.I. 딥러닝과 뇌과학을 기반으로 인공지능 영어 학습
                          친구 엘라 플랫폼을 만듭니다.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- end feature box item --> */}
                  <div
                    className="col-12 wow animate__fadeInRight"
                    data-wow-delay="0.2s"
                  >
                    <div className="w-100 h-1px bg-medium-gray margin-3-rem-tb md-margin-2-rem-tb"></div>
                  </div>
                  {/* <!-- start feature box item --> */}
                  <div
                    className="col-12 wow animate__fadeInRight"
                    data-wow-delay="0.4s"
                  >
                    <div className="feature-box feature-box-left-icon">
                      <div className="feature-box-content last-paragraph-no-margin">
                        {/* <!-- <span className="alt-font text-black font-weight-500 d-block margin-5px-bottom">Generating increased revenue</span> --> */}
                        <p
                          className={`w-85 xs-w-100 ${style.section5ContentReact1}`}
                        >
                          하나의 꿈을 가진 사람들이 모여 혁신적인 변화를
                          이루려는, <br />
                          미래를 향한 유나이티드어소시에이츠의 의지를 날개의
                          형태로 표현하였습니다.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- end feature box item -->
                          <!-- <div className="col-12 margin-4-rem-top d-inline-block wow " data-wow-delay="0.4s"><a href="index.html" className="btn btn-large btn-expand-ltr text-black section-link">Explore Features<span className="bg-shamrock-green"></span></a></div> --> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end section --> */}
        <section
          className="big-section cover-background"
          style={{ backgroundImage: "url('images/UA/aboutImg/aboutBackground.png')" }}
        >
          {/* <div className="opacity-extra-medium-2 bg-dark-slate-blue"></div> */}
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-xl-10 col-lg-8 col-md-10  position-relative">
                <h5 className={`text-white alt-font font-weight-700 ${style.section6TitleReact1}`}>Ethical AI</h5>
                {/* <a
                  href="https://www.youtube.com/watch?v=g0f_BRYJLJE"
                  className="popup-youtube video-icon-box video-icon-large position-relative d-inline-block margin-3-half-rem-bottom wow animate__bounceIn"
                >
                  <span>
                    <span className="video-icon bg-neon-blue">
                      <i className="icon-simple-line-control-play text-white"></i>
                      <span className="video-icon-sonar">
                        <span className="video-icon-sonar-afr bg-neon-blue"></span>
                      </span>
                    </span>
                  </span>
                </a> */}
                <div style={{ "marginBottom": "2vh" }}>
                  <span className={`text-white alt-font  letter-spacing-1px wow animate__bounceIn ${style.section6ContentReact1}`}>
                    {`At United Associates, we join the growing community of developers committed to harnessing the power of
                   tomorrow's technology mindfully and ethically. As we develop our technology, we are committed to fostering
                   a safe and productive environment with the appropriate measures to minimize the risk of misuse or
                    exploitation. We are committed to respecting the intellectual property of others and ensuring the protection
                     of our intellectual property.`}
                  </span>
                </div>

                <div>
                  <span className={`text-white alt-font  letter-spacing-1px wow animate__bounceIn ${style.section6ContentReact1}`}>
                    {`유나이티드어소시에이츠는 투명하고, 윤리적으로 안전한 미래 기술의 혁신을 바탕으로 이용자, 파트너 및 공동연구팀 등 모든 구성원들과 함께 성장할 수 있는 비즈니스 생태계를 이루고자 합니다. 신기술의 남용 또는 악용의 가능성을 최소화하고, 안전하고 생산적인 환경 조성 및 지적 재산권을 보호하기 위한 노력을 계속하겠습니다.`}
                  </span>
                </div>
                {/* <h3 className="alt-font text-white font-weight-600 margin-45px-bottom md-margin-35px-bottom xs-margin-25px-bottom wow animate__fadeIn">
                  <img src="images/UA/aboutImg/textlogo_w.png" />
                </h3> */}
              </div>
            </div>
          </div>
        </section>
        {/* 이메일 서브밋 폼 */}
        <ContactUs></ContactUs>

        {/* 구글맵 */}
        {/* <!-- start section --> */}
        {/* <section className="big-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 text-center margin-six-bottom">
                <h6 className="alt-font text-extra-dark-gray font-weight-500">
                  Google map style 01
                </h6>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12">
                <iframe
                  className="w-100 h-500px"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.843821917424!2d144.956054!3d-37.817127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d4c2b349649%3A0xb6899234e561db11!2sEnvato!5e0!3m2!1sen!2sin!4v1427947693651"
                ></iframe>
              </div>
            </div>
          </div>
        </section> */}
        {/* <!-- end section --> */}
        {/* height: 100vh;
        width: 100vw;
        background-image: url('img/signup.jpeg');
        background-repeat : no-repeat;
        background-size : cover; */}

        <div
          style={{
            backgroundImage: "url('images/UA/aboutImg/ua_map.png')",
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "cover",
            // objectFit: "contain",
            width: "100vw",
            height: "30vw",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "inherit",
            backgroundSize: "cover",
            backgroundPosition: "none",
          }}
        ></div>
        {/* <!-- start section --> */}
        {/* <!-- start section --> */}
        <section className="half-section">
          <div className="container">
            <div className="row justify-content-center">
              {/* <!-- start feature box item--> */}
              <div
                className="col-12 col-sm-auto sm-margin-15px-bottom wow animate__fadeIn text-start"
                data-wow-delay="0.2s"
              >
                <div className="d-flex justify-content-center align-items-center padding-15px-lr h-100">
                  <i className="feather icon-feather-headphones align-middle icon-extra-small text-gradient-magenta-orange margin-10px-right "></i>
                  <span className="text-extra-dark-gray alt-font text-medium ">
                    {env_value.CALL_NUMBER}
                  </span>
                </div>
              </div>
              {/* <!-- end feature box item--> */}
              {/* <!-- start feature box item--> */}
              <div
                className="col-12 col-sm-auto sm-margin-15px-bottom wow animate__fadeIn"
                data-wow-delay="0.4s"
              >
                <div className="d-flex justify-content-center align-items-center padding-15px-lr h-100">
                  <i className="feather icon-feather-mail align-middle icon-extra-small text-gradient-magenta-orange margin-10px-right"></i>
                  <a
                    href="mailto:no-reply@domain.com"
                    className="text-extra-dark-gray alt-font text-medium"
                  >
                    {env_value.COMP_EMAIL}
                  </a>
                </div>
              </div>
              {/* <!-- end feature box item--> */}
              {/* <!-- start feature box item--> */}
              <div
                className="col-12 col-sm-auto wow animate__fadeIn"
                data-wow-delay="0.6s"
              >
                <div className="d-flex justify-content-center align-items-center padding-15px-lr h-100">
                  <i className="feather icon-feather-globe align-middle icon-extra-small text-gradient-magenta-orange margin-10px-right"></i>
                  <a
                    href="#"
                    className="text-extra-dark-gray alt-font text-medium"
                  >
                    {env_value.ADDR}
                  </a>
                </div>
              </div>
              {/* <!-- end feature box item--> */}
            </div>
          </div>
        </section>
        {/* <!-- end section --> */}
      </>
    );
  }
}

export default About;

// 이메일 컴포넌트
export const ContactUs = () => {
  // const form = useRef();
  const form = useRef<any>(null);

  // 인풋 변수
  const [nameValue, setNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [messageValue, setMessageValue] = useState("");
  const [termsFlag, setTermsFlag] = useState(true);

  // name onChange Event
  const nameOnChange = (e) => {
    setNameValue(e.target.value);
  };
  // email onChange Event
  const emailOnChange = (e) => {
    setEmailValue(e.target.value);
  };
  // phone onChange Event
  const phoneOnChange = (e) => {
    setPhoneValue(e.target.value);
  };
  // message onChange Event
  const messageOnChange = (e) => {
    setMessageValue(e.target.value);
  };

  // setTermsFlag onChange Event
  const termsFlagOnChange = () => {
    if (termsFlag === true) {
      setTermsFlag(false);
    } else {
      setTermsFlag(true);
    }
  };

  // 벨리데이션 체크 함수
  // const validationCheck = () => {
  //   // e.preventDefault();
  //   // 벨리데이션 체크
  //   if (nameValue !== "") {
  //     if (emailValue !== "") {
  //       if (phoneValue !== "") {
  //         if (messageValue !== "") {
  //           // 약관동의
  //           // if (termsFlag === true) {
  //           // console.log("약관 동의까지 완료");
  //           console.log("리턴 서브밋 전");
  //           return sendEmail;
  //           // } else {
  //           //   alert("약관에 동의해주세요.");
  //           // }
  //         } else {
  //           alert("메시지를 입력해주세요.");
  //           // return false;
  //         }
  //       } else {
  //         alert("휴대폰 번호를 입력해주세요.");
  //         // return false;
  //       }
  //     } else {
  //       alert("이메일을 입력해주세요.");
  //       // return false;
  //     }
  //   } else {
  //     alert("이름을 입력해주세요.");
  //     // return false;
  //   }
  // };

  // 서브밋 이벤트
  const sendEmail = async (e) => {
    e.preventDefault();

    if (nameValue !== "") {
      if (emailValue !== "") {
        if (phoneValue !== "") {
          if (messageValue !== "") {
            if (termsFlag === true) {
              await emailjs
                .sendForm(
                  // service_id
                  emailApiKey.SERVICE_ID,
                  // template_id
                  emailApiKey.TEMPLATE_ID,
                  // form.current
                  form.current,
                  // user_id
                  emailApiKey.USERR_ID
                )
                .then(
                  (result) => {
                    console.log('메일 전송에 성공했습니다.[200]')
                    alert("문의가 접수되었습니다. 곧 연락드리겠습니다.");
                    console.log(result.text);
                  },
                  (error) => {
                    console.log('메일 전송에 실패했습니다.')
                    console.log(error.text);
                  }
                );
            } else {
              alert("개인정보 수집 및 이용에 동의해 주세요.");
              return false;
            }
          } else {
            alert("메시지를 입력해주세요");
            return false;
          }
        } else {
          alert("번호를 입력해주세요.");
          return false;
        }
      } else {
        alert("email을 입력해주세요.");
        return false;
      }
    } else {
      alert("이름을 입력해주세요.");
      return false;
    }
  };

  return (
    <div>
      <section className="margin-20px-top margin-20px-bottom wow animate__fadeIn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10">
              <div className="row justify-content-center">
                <div className="col-12 col-xl-6 col-lg-7 text-center margin-4-half-rem-bottom md-margin-3-rem-bottom">
                  <span className="alt-font letter-spacing-minus-1-half text-extra-medium d-block margin-5px-bottom text-gradient-sky-blue-pink">
                    Fill out the form and we’ll be in touch soon!
                  </span>
                  <h4 className="alt-font font-weight-600 text-extra-dark-gray">
                    How can we help you?
                  </h4>
                </div>
                <div className="col-12">
                  {/* <!-- start contact form --> */}
                  <form
                    // action="email-templates/contact-form.php"
                    // method="post"
                    ref={form}
                    onSubmit={sendEmail}
                  >
                    <div className="row row-cols-1 row-cols-md-2">
                      <div className="col margin-4-rem-bottom sm-margin-25px-bottom">
                        <input
                          className="medium-input bg-white margin-25px-bottom required"
                          type="text"
                          name="name"
                          placeholder="Your name"
                          value={nameValue}
                          onChange={nameOnChange}
                        />
                        <input
                          className="medium-input bg-white margin-25px-bottom required"
                          type="email"
                          name="email"
                          placeholder="Your email address"
                          value={emailValue}
                          onChange={emailOnChange}
                        />
                        <input
                          className="medium-input bg-white mb-0"
                          type="tel"
                          name="phone"
                          placeholder="Your mobile"
                          value={phoneValue}
                          onChange={phoneOnChange}
                        />
                      </div>

                      <div className="col margin-4-rem-bottom sm-margin-10px-bottom">
                        <textarea
                          className="medium-textarea h-200px bg-white"
                          name="message"
                          placeholder="Your message"
                          value={messageValue}
                          onChange={messageOnChange}
                        ></textarea>
                      </div>
                      <div className="col text-start sm-margin-30px-bottom">
                        <input
                          type="checkbox"
                          // name="terms_condition"
                          // id="terms_condition"
                          checked={termsFlag}
                          onChange={termsFlagOnChange}
                          className="terms-condition d-inline-block align-top w-auto mb-0 margin-5px-top margin-10px-right"
                        />
                        <label
                          // htmlFor="terms_condition"
                          className="text-small d-inline-block align-top w-85"
                        >
                          서비스 관련 문의를 위한 개인정보 수집 및 이용에
                          동의합니다.
                        </label>
                      </div>
                      <div className="col text-center text-md-end">
                        {/* <input type="hidden" name="redirect" value="" /> */}
                        {/* <button
                          className="btn btn-medium btn-gradient-light-purple-light-orange mb-0 submit"
                          // type="submit"
                          onClick={validationCheck}
                        >
                          Send Message
                        </button> */}
                        <button
                          className="btn btn-medium btn-gradient-light-purple-light-orange mb-0"
                          // onClick={validationCheck}
                          type="submit"
                          style={{ fontSize: "18px" }}
                        >
                          제출하기
                        </button>
                      </div>
                    </div>
                    {/* <div className="form-results d-none"></div> */}
                  </form>
                  {/* <!-- end contact form --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end section --> */}
      {/* </form> */}
    </div>
  );
};
