import React, { Component } from "react";
import style from "./Home.module.scss";
import { Props, State } from "../../Nav/index";

class Home extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  // 버튼 클릭시 새창으로 링크열기
  onClickExperienceReq = () => {
    window.open("https://forms.gle/UP9mN3uiru8i6p467");
  };
  onClickNews01 = () => {
    window.open("http://www.issuenbiz.com/news/articleView.html?idxno=12891");
  };
  onClickNews02 = () => {
    window.open("https://www.youtube.com/watch?v=veqRyerO3xE");
  };
  onClickNews03 = () => {
    window.open("http://www.ntoday.co.kr/news/articleView.html?idxno=92409");
  };
  onClickNews04 = () => {
    window.open(
      "http://dailymagazine.co.kr/news/newsview.php?ncode=1065578674626515"
    );
  };
  onClickNews05 = () => {
    window.open("https://www.startupn.kr/news/articleView.html?idxno=29482");
  };
  onClickYoutube = () => {
    window.open("https://youtu.be/unnmOGxz7Qk");
  };
  onClickAddNav = () => {
    const clickNav = document.getElementById("2nav_items_id");
    clickNav?.click();
  };
  render() {
    return (
      <div className={style.constDiv}>
        {/*  <!-- start section --> */}
        <section
          className="p-0 parallax white-move cover-background "
          style={{
            backgroundImage: "url('images/UA/homeImg/home_main.png')",
            height: "100vh",
            // background- position: center center;
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundSize: "cover",

            // background-repeat:  no-repeat;

            // background-attachment: fixed;

            // background-size:  cover;


          }}

        >
          {/* <div
          // className="parallax white-move mobileoff-fullscreen-top-space md-h-600px sm-h-500px"
          // data-slider-options='{ "slidesPerView": 1, "loop": true, "pagination": { "el": ".swiper-pagination", "clickable": true }, "navigation": { "nextEl": ".swiper-button-next-nav", "prevEl": ".swiper-button-previous-nav" }, "autoplay": { "delay": 4500, "disableOnInteraction": false }, "keyboard": { "enabled": true, "onlyInViewport": true }, "effect": "slide" }'
          > */}
          <div className="swiper-wrapper">
            {/* <!-- start slider item --> */}
            {/* <div
              className="swiper-slide cover-background "
              style={{
                // backgroundImage: "url('images/UA/homeImg/home_main.png')",
                // backgroundSize: "cover",
                // backgroundRepeat: "no-repeat",
                // backgroundAttachment: "inherit",
                // backgroundPosition: "none",
              }}
            > */}
            <div className="overlay-bg bg-gradient-dark-slate-blue"></div>
            <div
              className="container h-100"
              style={
                window.matchMedia("screen and (min-width: 1199px)").matches
                  ? { maxWidth: "1570px" }
                  : {}
              }
            >
              <div className="row h-100">
                <div
                  className="col-12 offset-sm-0 col-sm-10 offset-md-1 col-md-10 offset-lg-1 col-lg-10 offset-xl-1 col-xl-10 h-100 d-flex justify-content-center flex-column position-relative"
                >
                  <h6 className="alt-font text-shadow-double-large text-white  font-weight-400 margin-40px-bottom xs-margin-20px-bottom">
                    모두에게 평등한 교육 기회를 제공하는 인공지능 기술을
                    연구합니다.
                  </h6>
                  <h4 className="alt-font text-shadow-double-large font-weight-600 text-white margin-55px-bottom w-100 md-w-100 md-no-text-shadow xs-margin-35px-bottom line-height-75px sm-line-height-50px">
                    아이들이 꿈을 이루는 데에
                    <br /> 더이상 영어가 장애물이 되지 않도록
                  </h4>

                </div>
              </div>
            </div>
            {/* </div> */}
            {/* <!-- end slider item --> */}

            {/* <!-- end slider item -->                           */}
          </div>
          {/* <!-- start slider pagination --> */}
          <div className="swiper-pagination swiper-light-pagination"></div>
          {/* <!-- end slider pagination --> */}
          {/* </div> */}
        </section>
        {/* // <!-- end section --> */}

        {/* <!-- start section --> */}
        <section
          className=" big-section pt-10"
          style={{ backgroundColor: "#F7F7F7" }}
        >
          <div
            className="container pb-4"
            style={
              window.matchMedia("screen and (min-width: 1199px)").matches
                ? { maxWidth: "1370px" }
                : {}
            }
          >
            <div className="row justify-content-center">
              <div className="col-md-12 text-center margin-seven-bottom">
                <div className="margin-20px-bottom sm-margin-10px-bottom m-0">
                  <span className="alt-font text-medium text-gradient-sky-blue-pink font-weight-500">
                    Startup for Language Education
                  </span>
                </div>
                <div >
                  <h4
                    className="alt-font text-black font-weight-600  "
                  // style={{ fontSize: "36px" }}
                  >
                    A.I. Edutech Lab
                  </h4>
                </div>
                <div className="text-center">
                  <span style={{ color: "black", fontSize: "17px" }}>
                    유나이티드어소시에이츠는 세계 최고 수준의 자연어처리
                    인공지능 기술을 연구합니다.
                  </span>
                </div>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 justify-content-center">
              {/* <!-- start services item --> */}
              <div className="col  md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn ">
                <div className="bg-white box-shadow-medium last-paragraph-no-margin">
                  <img src="images/UA/homeImg/ai_lab_3.png" alt="" />
                  <div className="text-center position-relative padding-3-rem-all lg-padding-2-rem-lr">
                    {/* <span className="w-2px h-50px bg-fast-blue absolute-middle-center top-0px xs-h-40px"></span> */}
                    <div
                      className="alt-font text-extra-dark-gray font-weight-500 margin-10px-bottom"
                      style={{ fontSize: "17px" }}
                    >
                      영어권 홈스테이와 같은 말하기 환경 조성
                    </div>
                    <p style={{ fontSize: "17px" }}>
                      IOT 환경 내 멀티모달 센서와 대화 내용의 기억DB처리로
                      자연스러운 상호 대화
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- end services item --> */}
              {/* <!-- start services item --> */}
              <div
                className="col md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                data-wow-delay="0.2s"
              >
                <div className="bg-white box-shadow-medium last-paragraph-no-margin ">
                  <img
                    src="images/UA/homeImg/ai_lab_1.png"
                    alt=""
                  // style={{ maxHeight: "240.15px", backgroundSize: "contain" }}
                  />
                  <div className="text-center position-relative padding-3-rem-all lg-padding-2-rem-lr">
                    {/* <span className="w-2px h-50px bg-fast-blue absolute-middle-center top-0px xs-h-40px"></span> */}
                    <div
                      className="alt-font text-extra-dark-gray font-weight-500 margin-10px-bottom"
                      style={{ fontSize: "17px" }}
                    >
                      기억, 감정 인지로 진화하는 공감대화
                    </div>
                    <p style={{ fontSize: "17px" }}>
                      자율성장 인공지능 기술 기반의 친구같은 3D 캐릭터와의
                      의미있는 관계형 대화
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- end services item --> */}

              {/* <!-- start services item --> */}
              <div className="col wow animate__fadeIn" data-wow-delay="0.4s">
                <div className="bg-white box-shadow-medium last-paragraph-no-margin">
                  {/* 이미지 사이즈 799 x 533 (px) */}
                  <img src="images/UA/homeImg/ai_lab_2.png" alt="" />
                  <div className="text-center position-relative padding-3-rem-all lg-padding-2-rem-lr">
                    {/* <span className="w-2px h-50px bg-fast-blue absolute-middle-center top-0px xs-h-40px"></span> */}
                    <div
                      className="alt-font text-extra-dark-gray font-weight-500 margin-10px-bottom"
                      style={{ fontSize: "17px" }}
                    >
                      영어 활용 수준을 높이는 AI 영어 패키지
                    </div>
                    <p style={{ fontSize: "17px" }}>
                      효과적인 영어 학습 및 글로벌 비즈니스를 지원하는 인공지능
                      영어 솔루션
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- end services item --> */}
            </div>
          </div>
        </section>
        {/* <!-- end section --> */}

        {/* <!-- start section --> */}
        {/* <!-- 요가 동영상 부분 --> */}
        <section
          className="pt-0 big-section wow animate__fadeIn mt-5 pt-5"
        // style={{ marginTop: "160px" }}
        >
          <div
            className="container "
            style={
              window.matchMedia("screen and (min-width: 1199px)").matches
                ? { maxWidth: "1370px" }
                : {}
            }
          >
            <div
              className="row align-items-center justify-content-center"
            // style={{ marginTop:"" }}
            >
              <div className="col-12 col-lg-4 md-margin-5-rem-bottom xs-margin-5-rem-top  wow animate__fadeIn m-0"
              >
                <div className="row justify-content-center">
                  <div className="col-md-12 text-center text-lg-start margin-seven-bottom">
                    <div className="margin-20px-bottom sm-margin-10px-bottom">
                      <span className="alt-font text-gradient-sky-blue-pink font-weight-500">
                        Real English, Real Friendship
                      </span>
                    </div>
                    <div
                    // style={{ marginBottom: "20px" }}
                    >
                      <h4
                        className="alt-font text-black font-weight-700  "
                      // style={{ fontSize: "36px" }}
                      >
                        실제로 사용하는 영어를 <br />
                        배우는 동료 학습
                      </h4>
                    </div>
                  </div>
                  <div className="d-inline-block text-center text-lg-start">
                    <a
                      onClick={this.onClickAddNav}
                      // href="https://youtu.be/unnmOGxz7Qk"
                      target="_blank"
                      className="btn btn-fancy sm-bt  btn-medium btn-gradient-sky-blue-pink xs-margin-50px-bottom"
                      style={{ fontSize: "18px", borderRadius: "2px" }}
                    >{`더 알아보기 >`}</a>
                    {/* <!-- <a href="our-process.html" className="btn btn-link btn-large text-white top-minus-5px font-weight-400">How we work</a> --> */}
                  </div>
                </div>
              </div>
              <div
                className="col-12 col-lg-6 offset-lg-1 home-yoga-meditation wow animate__fadeIn"
                data-wow-delay="0.4s"
              >
                <div className="outside-box-right position-relative">
                  <img
                    src="images/UA/homeImg/video.png"
                    className="overflow-hidden border-radius-4px"
                    alt=""
                  />
                  <a
                    onClick={this.onClickYoutube}
                    // href="https://youtu.be/unnmOGxz7Qk"
                    className="popup-youtube absolute-middle-center video-icon-box video-icon-extra-large left-0px wow animate__fadeIn"
                    data-wow-delay="0.2s"
                  >
                    <span>
                      <span className="video-icon bg-white box-shadow-large">
                        <i className="fas fa-play text-salmon-rose"></i>
                      </span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>


        {/* <!-- start section --> */}
        <section className="big-section wow animate__fadeIn">
          <div
            className="container"
            style={
              window.matchMedia("screen and (min-width: 1299px)").matches
                ? { maxWidth: "1370px" }
                : {}
            }
          >
            <div className="row justify-content-center wow animate__fadeIn ">
              <div className="col-12 col-xl-4 col-lg-4 col-sm-7  d-flex flex-column text-center text-lg-start md-margin-6-rem-bottom">
                <div className="margin-20px-bottom sm-margin-10px-bottom ">
                  <span className="alt-font text-medium text-gradient-sky-blue-pink  font-weight-500">
                    Customized Technology with AI
                  </span>
                </div>
                <div className="alt-font line-height-46px  text-extra-dark-gray font-weight-600 margin-20px-bottom md-margin-30px-bottom xs-w-90 mx-auto mx-sm-0">
                  <h4>
                    A.I. English
                    <br /> Study Tools <br />
                    For Self-directed <br />
                    Learning
                  </h4>
                </div>
                <div className="mt-auto mx-auto mx-lg-0 ">
                  <a
                    onClick={this.onClickExperienceReq}
                    // href="https://forms.gle/UP9mN3uiru8i6p467"
                    className="btn btn-fancy btn-small btn-gradient-sky-blue-pink "
                    style={{ fontSize: "18px", borderRadius: "2px" }}
                  >
                    체험 신청하기
                  </a>
                </div>
              </div>
              <div
                className="col-12 col-xl-6 col-lg-6 last-paragraph-no-margin wow animate__fadeIn "
                data-wow-duration="0.3"
              >
                <div className="row row-cols-1 row-cols-lg-2 row-cols-sm-8">
                  {/* <!-- start feature box item --> */}
                  <div className="col-12 col-xl-6 col-sm-12 margin-100px-bottom sm-margin-30px-bottom xs-margin-40px-bottom wow animate__fadeIn">
                    <div className=" feature-box feature-box-left-icon text-center text-lg-start">
                      <div className="col-12 col-xl-2  feature-box-icon ">
                        <i className="feather icon-feather-thumbs-up icon-medium text-gradient-sky-blue-pink margin-35px-bottom md-margin-15px-bottom sm-margin-10px-bottom"></i>
                      </div>
                      <div className=" col-12 col-xl-8  sfeature-box-content last-paragraph-no-margin align-items-center">
                        <span className="alt-font font-weight-500 margin-10px-bottom d-block text-extra-dark-gray">
                          Speech Advisor
                        </span>
                        <p>초보자를 위한 다양한 표현법 제안</p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- end feature box item --> */}
                  {/* <!-- start feature box item --> */}
                  <div
                    className="col-12 col-xl-6 col-sm-12  margin-60px-bottom sm-margin-30px-bottom xs-margin-40px-bottom wow animate__fadeIn"
                    data-wow-delay="0.2s"
                  >
                    <div className="feature-box feature-box-left-icon text-center text-lg-start">
                      <div className="col-12 col-xl-2 feature-box-icon">
                        <i className="feather icon-feather-message-square icon-medium text-gradient-sky-blue-pink margin-35px-bottom md-margin-15px-bottom sm-margin-10px-bottom"></i>
                      </div>
                      <div className="col-12 col-xl-8 feature-box-content last-paragraph-no-margin">
                        <span
                          className="alt-font font-weight-500 margin-10px-bottom d-block text-extra-dark-gray"
                        // style={{ fontSize: "18px" }}
                        >
                          Questioner
                        </span>
                        <p >
                          영어 콘텐츠에 대한 자동질문 생성
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- end feature box item --> */}
                  {/* <!-- start feature box item --> */}
                  <div
                    className="col-12 col-xl-6 col-sm-12 margin-60px-bottom sm-margin-30px-bottom xs-margin-40px-bottom wow animate__fadeIn"
                    data-wow-delay="0.4s"
                  >
                    <div className="feature-box feature-box-left-icon text-center text-lg-start">
                      <div className="col-12 col-xl-2 feature-box-icon ">
                        <i className="feather icon-feather-edit icon-medium text-gradient-sky-blue-pink margin-35px-bottom md-margin-15px-bottom sm-margin-10px-bottom"></i>
                      </div>
                      <div className="col-12 col-xl-8 feature-box-content last-paragraph-no-margin">
                        <span
                          className="alt-font font-weight-500 margin-10px-bottom d-block text-extra-dark-gray"
                        // style={{ fontSize: "18px" }}
                        >
                          Summary Note
                        </span>
                        <p>
                          학습 내용의 자동 노트 정리
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- end feature box item --> */}
                  {/* <!-- start feature box item --> */}
                  <div
                    className="col-12 col-xl-6 col-sm-12 xs-margin-40px-bottom wow animate__fadeIn"
                    data-wow-delay="0.6s"
                  >
                    <div className="feature-box feature-box-left-icon text-center text-lg-start">
                      <div className="col-12 col-xl-2 feature-box-icon ">
                        <i className="feather icon-feather-layers icon-medium text-gradient-sky-blue-pink margin-35px-bottom md-margin-15px-bottom sm-margin-10px-bottom"></i>
                      </div>
                      <div className="col-12 col-xl-8 feature-box-content last-paragraph-no-margin">
                        <span
                          className="alt-font font-weight-500 margin-10px-bottom d-block text-extra-dark-gray"
                        // style={{ fontSize: "18px" }}
                        >
                          Essay Outliner
                        </span>
                        <p>
                          영어 에세이 목차 자동생성
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- end feature box item --> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end section --> */}
        {/* <!-- start section --> */}
        <section
          className="big-section bg-light-gray wow animate__fadeIn"
          style={{ padding: "7%", paddingBottom: "5%" }}
        >
          <div
            className="container"
            style={
              window.matchMedia("screen and (min-width: 1199px)").matches
                ? { maxWidth: "1370px" }
                : {}
            }
          >
            <div className="row justify-content-center">
              <div className="col-md-12 text-center ">
                <div className="alt-font text-extra-dark-gray font-weight-700">
                  <h4>이런 분들께 추천합니다</h4>
                </div>
              </div>
            </div>
            <div className="row justify-content-center margin-3-rem-tb">
              {/* <!-- start fancy text box item --> */}
              <div
                className="col-12 col-lg-5 col-md-8 col-sm-10 margin-10px-left margin-10px-right margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn  "
                data-wow-delay="0.2s"

              >
                <div className=" row colfeature-box feature-box-left-icon-middle padding-3-half-rem-all bg-white box-shadow-small box-shadow-extra-large-hover border-radius-6px lg-padding-1-half-rem-lr xs-padding-2-rem-all">
                  <div className="col-12 col-xl-3 col-lg-3 sfeature-box-icon text-center ">
                    {/* <!-- <h4 className="alt-font text-gradient-sky-blue-dark-pink font-weight-600 letter-spacing-minus-1px mb-0">2010</h4> --> */}
                    <img
                      style={{ width: "5vh" }}
                      src="images/UA/homeImg/icon_2.png"
                      alt=""
                    />
                  </div>
                  <div className="col-12 feature-box-content alt-font text-center mt-3 mt-lg-0">
                    <span
                      className="text-extra-dark-gray font-weight-700"

                    >
                      영어 교육 서비스 공급자 (개인/기업)
                    </span>
                  </div>
                </div>
              </div>
              {/* <div style={{ padding: "1px" }}></div> */}

              <div
                className="col-12 col-lg-5 col-md-8 col-sm-10 margin-10px-left margin-10px-right margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn "
                data-wow-delay="0.2s"

              >
                <div className=" row colfeature-box feature-box-left-icon-middle padding-3-half-rem-all bg-white box-shadow-small box-shadow-extra-large-hover border-radius-6px lg-padding-1-half-rem-lr xs-padding-2-rem-all">
                  <div className="col-12 col-xl-3 col-lg-3 sfeature-box-icon text-center ">
                    {/* <!-- <h4 className="alt-font text-gradient-sky-blue-dark-pink font-weight-600 letter-spacing-minus-1px mb-0">2010</h4> --> */}
                    <img
                      style={{ width: "5vh" }}
                      src="images/UA/homeImg/icon_1.png"
                      alt=""
                    />
                  </div>
                  <div className="col-12 feature-box-content alt-font text-center mt-3 mt-lg-0">
                    <span
                      className="text-extra-dark-gray font-weight-700"

                    >
                      영어를 사용하는 글로벌 비즈니스
                    </span>
                  </div>
                </div>
              </div>
              {/* <!-- end fancy text box item --> */}

            </div>
          </div>
        </section >
        {/* <!-- end section --> */}

        {/* start section */}
        <section>
          <div className="row">
            <div className="row justify-content-center">
              <div className="col-12 col-xl-6 col-lg-6 col-sm-8 text-center md-margin-50px-left sm-margin-30px-left margin-4-rem-bottom md-margin-3-rem-bottom xs-margin-1-rem-bottom wow animate__fadeIn">
                <span className="d-inline-block alt-font text-medium text-gradient-sky-blue-pink font-weight-500 margin-20px-bottom sm-margin-15px-bottom">
                  United Associates News
                </span>
                <div
                  className="alt-font text-extra-dark-gray font-weight-600 letter-spacing-minus-1px"
                  style={{ color: "blacks" }}
                >
                  <h4>
                    United Associates
                    <br />
                    Business Updates
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div
            className="container"
            style={
              window.matchMedia("screen and (min-width: 1199px)").matches
                ? {
                  maxWidth: "1570px",
                  display: "flex",
                  justifyContent: "center",
                }
                : { display: "flex" }
            }
          >
            <div className="row d-flex justify-content-center" style={{ alignItems: "center" }}>
              {/* 카드 시작 */}
              {/* <div
                className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 card border-none wow animate__fadeIn"
                style={{ width: " 21rem" }}
              >
                <img
                  onClick={this.onClickNews01}
                  src="images/UA/homeImg/news1.png"
                  className="card-img-top"
                  alt=""
                  style={{ cursor: "pointer" }}
                />
                <div className="card-body margin-20px-top">
                  <a
                    onClick={this.onClickNews01}
                    className="alt-font font-weight-500 text-extra-medium text-extra-dark-gray d-block margin-20px-bottom xs-margin-10px-bottom line-height-30px"
                    style={{ fontSize: "19px", cursor: "pointer" }}
                  >
                    오랜 숙원 '영어정복'
                    <br /> 스타트업과 함께 푼다.
                  </a>
                  <p className="w-95">2021-10-12</p>
                </div>
              </div> */}
              {/* 카드 끝 */}
              {/* 카드 시작 */}
              <div
                className="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 card border-none wow animate__fadeIn"
                data-wow-delay="0.2s"
                style={{ width: " 21rem" }}
              >
                <img
                  onClick={this.onClickNews02}
                  src="images/UA/homeImg/news3.png"
                  className="card-img-top"
                  alt=""
                  style={{ cursor: "pointer" }}
                />
                <div className="card-body sm-margin-5px-top xl-margin-20px-top p-0 p-lg-3">
                  {/* <h6 className="card-title" style={{ color: "black" }}>
                    Card title
                  </h6>
                  <p className="card-text">2021-10-12</p> */}
                  <a
                    // href="https://www.youtube.com/watch?v=veqRyerO3xE"
                    onClick={this.onClickNews02}
                    className="alt-font  font-weight-600 text-extra-medium text-extra-dark-gray d-block line-height-30px sm-line-height-20px"
                    style={{ fontSize: "16px", cursor: "pointer" }}
                  >
                    비대면 영어발화 학습환경 조성 플랫폼,Ella
                  </a>
                  <p className="">2021-11-17</p>
                </div>
              </div>
              {/* 카드 끝 */}
              {/* 카드 시작 */}
              <div
                className=" col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 card border-none wow animate__fadeIn"
                data-wow-delay="0.4s"
                style={{ width: " 21rem" }}
              >
                <img
                  onClick={this.onClickNews03}
                  src="images/UA/homeImg/news2.png"
                  className="card-img-top"
                  alt=""
                  style={{ cursor: "pointer" }}
                />
                <div className="card-body sm-margin-5px-top xl-margin-20px-top p-0 p-lg-3 ">
                  {/* <h6 className="card-title" style={{ color: "black" }}>
                    Card title
                  </h6>
                  <p className="card-text">2021-10-12</p> */}
                  <a
                    // href="http://www.ntoday.co.kr/news/articleView.html?idxno=92409"
                    onClick={this.onClickNews03}
                    className="alt-font font-weight-600 text-extra-medium text-extra-dark-gray d-block  line-height-30px sm-line-height-20px"
                    style={{ fontSize: "16px", cursor: "pointer" }}
                  >
                    UA-카이스트, IITP 'ICT R&D 혁신 바우처 사업' 성료
                  </a>
                  <p className="w-95">2022-06-08</p>
                </div>
              </div>
              {/* 카드 끝 */}
              {/* 카드 시작 */}
              <div
                className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 card border-none wow animate__fadeIn"
                data-wow-delay="0.6s"
                style={{ width: " 21rem" }}
              >
                <img
                  onClick={this.onClickNews04}
                  src="images/UA/homeImg/news4.png"
                  className="card-img-top"
                  alt=""
                  style={{ cursor: "pointer" }}
                />
                <div className="card-body sm-margin-5px-top xl-margin-20px-top p-0 p-lg-3 ">
                  {/* <h6 className="card-title" style={{ color: "black" }}>
                    Card title
                  </h6>
                  <p className="card-text">2021-10-12</p> */}
                  <a
                    // href="htxtp://dailymagazine.co.kr/news/newsview.php?ncode=1065578674626515"
                    onClick={this.onClickNews04}
                    className="alt-font font-weight-600 text-extra-medium text-extra-dark-gray d-block line-height-30px sm-line-height-20px"
                    style={{ fontSize: "16px", cursor: "pointer" }}
                  >
                    AI 영어 플랫폼 Ella, 중소벤처기업부 TIPS 선정
                  </a>
                  <p className="w-95">2022-06-17</p>
                </div>
              </div>
              {/* 카드 끝 */}
              {/* 카드 시작 */}
              <div
                className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 card border-none wow animate__fadeIn"
                data-wow-delay="0.8s"
                style={{ width: " 21rem" }}
              >
                <img
                  onClick={this.onClickNews05}
                  src="images/UA/homeImg/news5.png"
                  className="card-img-top"
                  alt=""
                  style={{ cursor: "pointer" }}
                />
                <div className="card-body sm-margin-5px-top xl-margin-20px-top p-0 p-lg-3 ">
                  {/* <h6 className="card-title" style={{ color: "black" }}>
                    Card title
                  </h6>
                  <p className="card-text">2021-10-12</p> */}
                  <a
                    onClick={this.onClickNews05}
                    // href="http://www.issuenbiz.com/news/articleView.html?idxno=12891"
                    className="alt-font font-weight-600 text-extra-medium text-extra-dark-gray d-block  line-height-30px sm-line-height-20px"
                    style={{ fontSize: "16px", cursor: "pointer" }}
                  >
                    UA, '2022 에듀테크 코리아
                    <br /> 페어' 서 우수상 수상
                  </a>
                  <p className="w-95">2022-09-28</p>
                </div>
              </div>
              {/* 카드 끝 */}
            </div>
          </div>
        </section>
        {/* end section */}

        <section
          className="parallax wow animate__fadeIn"
          data-parallax-background-ratio="0.2"
          style={{
            backgroundImage: "url('images/UA/homeImg/hiring.png')",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "inherit",
            backgroundSize: "cover",
            backgroundPosition: "none",
          }}
        >
          {/* <div className="opacity-full " 
          style={{ objectFit: "cover", height:"auto" }}
          ></div> */}
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-xl-6 col-md-6 col-sm-10 position-relative text-center text-md-start sm-margin-30px-bottom">
                <h6
                  className="alt-font font-weight-500 text-white mb-0 line-height-50px"
                // style={{ lineHeight: "60px" }}
                >
                  UA는 세계 수준의 A.I. 기술을 바탕으로
                  <br />
                  다음 세대를 위한 영어 교육에 도전합니다
                </h6>
              </div>
              <div className="col-12 col-xl-5 col-md-4 position-relative text-center text-md-end">
                <a
                  href="https://www.rocketpunch.com/companies/unitedassociates/jobs"
                  className="btn btn-large btn-gradient-light-purple-light-orange"
                  style={{ fontSize: "18px", borderRadius: "2px" }}

                >
                  채용공고 보기
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end section --> */}
      </div >
    );
  }
}

export default Home;
