// import react from "react";
import React, { Component } from "react";
import { State, Props } from ".";
import { env_value } from "../../lib/.env";

class Footer extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      menuToggle: false,
      currentPageIndex: 0,
      menus: [
        // { name: "" },
        { name: "Home" },
        { name: "About" },
        { name: "Ella" },
      ],
    };
  }
  useStateMenuToggle = (flag: boolean, idx) => {
    this.setState({ menuToggle: flag, currentPageIndex: idx });
  };

  footerNavXML = (value, index) => {
    const { name } = value;
    let nav_index = index;
    return (
      <div

        key={`${index}footer${name}`}
        className={
          "row col-4 text-center  xs-margin-25px-bottom"
        }
        style={{
          "cursor": "pointer"
        }}
      >
        <h6
          className=" col-lg-alt-font font-weight-500 d-block mb-5  text-white margin-20px-bottom xs-margin-10px-bottom"

          onClick={() => {
            if (index >= 0) {
              document.getElementById(`${nav_index}nav_items_id`)?.click();
            }
          }}
        >
          {name}
        </h6>
      </div>
    );
  };

  render() {
    const { menus } = this.state;
    return (
      // <!-- start footer -->
      <footer className="footer-dark bg-slate-blue">
        <div className="footer-top padding-four-tb lg-padding-eight-tb sm-padding-50px-tb">
          <div className="container">
            <div className="row justify-content-center">
              {/* <!-- start footer column --> */}
              <div
                className="col-12 col-lg-0 d-lg-none col-sm-12 order-5 order-lg-0 last-paragraph-no-margin text-center"
              >
                {/* <a
                  href="/"
                  style={{
                    display: "inline-block",
                    width: "82.13px",
                    height: "44.82px",
                    marginBottom: "25.6px",
                  }}
                  className="footer-logo d-inline-block"
                >
                  <img
                    style={{
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                    src="images/UA/homeImg/footer_logo.png"
                    data-at2x="images/UA/homeImg/footer_logo.png"
                    alt=""
                  />
                </a> */}
              </div>
              {menus &&
                menus.map((value, index) => this.footerNavXML(value, index))
                // <div></div>
              }
              <div className="d-none d-lg-block col-12 col-lg-12 col-sm-12 order-5 order-lg-0 last-paragraph-no-margin">
                {/* <a
                  href="/"
                  style={{
                    // width: "82.13px",
                    // height: "44.82px",
                    marginBottom: "25.6px",
                  }}
                  className="footer-logo d-inline-block"
                >
                  <img
                    style={{
                      // width: "80px",
                      // height: "44px",

                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                    src="images/UA/homeImg/footer_logo.png"
                    data-at2x="images/UA/homeImg/footer_logo.png"
                    alt=""
                  />
                </a> */}
              </div>
              {/* <!-- end footer column --> */}
              {/* <!-- start footer column --> */}



              {/* <!-- end footer column --> */}

            </div>
            <div className="row justify-content-center">
              {/* <!-- start footer column --> */}
              <div className="col-12 col-lg-6 col-xl-6 col-sm-6 order-5 order-xl-0 text-sm-center text-md-start last-paragraph-no-margin text-center">
                <p>
                  {env_value.COMP_FULL_NAME_EN}
                  <br />
                  {env_value.COMP_FULL_NAME_KR}
                  <br />
                  <br />
                  대표자명: {env_value.COMP_CEO_NAME}<br />
                  사업장주소지 : {env_value.FOOTER_ADDR}<br />
                  사업자등록번호 : {env_value.BUSINESS_REGISTRATION_NUMBER}<br />
                  통신판매업번호 : {env_value.MAIL_ORDER_BUSINESS_NUMBER}<br />
                  전화번호 : {env_value.CALL_NUMBER}<br />
                  이메일 : {env_value.COMP_EMAIL}<br />
                  <br />
                  Copyright ⓒ 2024 UA All rights reserved.
                </p>
              </div>
              {/* <!-- end footer column --> */}
              <div className="col-0 col-lg-1 offset-xl-1 col-md-0 col-sm-0 order-lg-0"></div>
              {/* <div className="col-0 col-xl-0 col-lg-1 col-md-0 col-sm-0 order-4 order-lg-0">|</div> */}
              {/* <div className="col-0 col-xl-1 col-lg-1 col-md-0 col-sm-0 order-4 order-lg-0"></div> */}
              <div className="col-12 col-xl-3 col-lg-5 col-md-6 col-sm-12 order-4 order-md-5 order-lg-0 text-sm-center text-md-end xs-margin-25px-bottom text-center d-inline-flex align-content-end flex-wrap justify-content-sm-center">
                {/* <p className="w-full h-full">
                  <br className="d-none d-md-block" />
                  <br className="d-none d-md-block" />
                  <b>E.</b>
                  {` ${env_value.COMP_EMAIL}`}
                  <br />
                   개인정보처리방침
                </p> */}
                <div className="d-flex flex-row gap-4 mb-0 mb-md-0 mb-sm-4">
                  <button type="button" className="d-block btn" style={{
                    color: "#8890a4",
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: '15px',
                    padding: 0,
                    margin: 0,
                    border: 0,
                  }} onClick={()=>{
                    window.open(env_value.TERMS_OF_SERVICE_LINK, '_blank', 'noopener, norefferrer')
                  }}>서비스 이용약관</button>
                  <button type="button" className="d-block btn" style={{
                    color: "#8890a4",
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: '15px',
                    padding: 0,
                    margin: 0,
                    border: 0,
                  }} onClick={()=>{
                    window.open(env_value.PRIVACY_POLICY_LINK, '_blank', 'noopener, norefferrer')
                  }}>개인정보 처리방침</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- end footer --> */}
        {/* <!-- start scroll to top -->
        <a className="scroll-top-arrow" href="javascript:void(0);"><i className="feather icon-feather-arrow-up"></i></a>
        <!-- end scroll to top --> */}
      </footer>
    );
  }
}
export default Footer;
